@import "variables";


/*CUSTOM begin*/
.transparent[class^="ToolbarIcon-"] span:before,
.transparent[class*=" ToolbarIcon-"] span:before {
    color: unset !important;
}

.no-transparent[class^="ToolbarIcon-"] span:before,
.no-transparent[class*=" ToolbarIcon-"] span:before {
    opacity: unset !important;
}
/* CUSTOM end*/

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?se26ke');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?se26ke#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?se26ke') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?se26ke') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?se26ke##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ToolbarIcon-"], [class*=" ToolbarIcon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*font-size: 24px;*/
}

.ToolbarIcon-E-DesignerEditGraph {
  &:before {
    content: $ToolbarIcon-E-DesignerEditGraph; 
  }
}
.ToolbarIcon-E-DesignerVerbraucher {
  &:before {
    content: $ToolbarIcon-E-DesignerVerbraucher; 
  }
}
.ToolbarIcon-E-DesignerWechselrichter {
  &:before {
    content: $ToolbarIcon-E-DesignerWechselrichter; 
  }
}
.ToolbarIcon-E-DesignerBatterie {
  &:before {
    content: $ToolbarIcon-E-DesignerBatterie; 
  }
}
.ToolbarIcon-E-DesignerSimulieren .path1 {
  &:before {
    content: $ToolbarIcon-E-DesignerSimulieren-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-E-DesignerSimulieren .path2 {
  &:before {
    content: $ToolbarIcon-E-DesignerSimulieren-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-E-DesignerSimulieren .path3 {
  &:before {
    content: $ToolbarIcon-E-DesignerSimulieren-path3;  
    margin-left: -1em;  
    color: rgb(254, 254, 254);
  }
}
.ToolbarIcon-E-DesignerSimulieren .path4 {
  &:before {
    content: $ToolbarIcon-E-DesignerSimulieren-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-E-DesignerErtrag {
  &:before {
    content: $ToolbarIcon-E-DesignerErtrag; 
  }
}
.ToolbarIcon-E-DesignerHorizont {
  &:before {
    content: $ToolbarIcon-E-DesignerHorizont; 
  }
}
.ToolbarIcon-E-DesignerDCAC .path1 {
  &:before {
    content: $ToolbarIcon-E-DesignerDCAC-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-E-DesignerDCAC .path2 {
  &:before {
    content: $ToolbarIcon-E-DesignerDCAC-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-E-DesignerACDC .path1 {
  &:before {
    content: $ToolbarIcon-E-DesignerACDC-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-E-DesignerACDC .path2 {
  &:before {
    content: $ToolbarIcon-E-DesignerACDC-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-E-DesignerGF .path1 {
  &:before {
    content: $ToolbarIcon-E-DesignerGF-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-E-DesignerGF .path2 {
  &:before {
    content: $ToolbarIcon-E-DesignerGF-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-E-DesignerLupe {
  &:before {
    content: $ToolbarIcon-E-DesignerLupe; 
  }
}
.ToolbarIcon-E-DesignerEnergieFluss {
  &:before {
    content: $ToolbarIcon-E-DesignerEnergieFluss; 
  }
}
.ToolbarIcon-E-Designer-I {
  &:before {
    content: $ToolbarIcon-E-Designer-I; 
  }
}
.ToolbarIcon-E-DesignerPen {
  &:before {
    content: $ToolbarIcon-E-DesignerPen; 
  }
}
.ToolbarIcon-E-DesignerDelete {
  &:before {
    content: $ToolbarIcon-E-DesignerDelete; 
  }
}
.ToolbarIcon-E-DesignerAdd {
  &:before {
    content: $ToolbarIcon-E-DesignerAdd; 
  }
}
.ToolbarIcon-E-DesignerCopy {
  &:before {
    content: $ToolbarIcon-E-DesignerCopy; 
  }
}
.ToolbarIcon-E-DesignerAddRoof .path1 {
  &:before {
    content: $ToolbarIcon-E-DesignerAddRoof-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-E-DesignerAddRoof .path2 {
  &:before {
    content: $ToolbarIcon-E-DesignerAddRoof-path2;  
    margin-left: -1.017578125em;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-E-DesignerAddRoof .path3 {
  &:before {
    content: $ToolbarIcon-E-DesignerAddRoof-path3;  
    margin-left: -1.017578125em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-E-DesignerHome {
  &:before {
    content: $ToolbarIcon-E-DesignerHome; 
  }
}
.ToolbarIcon-E-DesignerInfo {
  &:before {
    content: $ToolbarIcon-E-DesignerInfo; 
  }
}
.ToolbarIcon-E-DesignerEnergieBilanz {
  &:before {
    content: $ToolbarIcon-E-DesignerEnergieBilanz; 
  }
}
.ToolbarIcon-E-DesignerAuslegungsVarianten {
  &:before {
    content: $ToolbarIcon-E-DesignerAuslegungsVarianten; 
  }
}
.ToolbarIcon-E-DesignerRevert {
  &:before {
    content: $ToolbarIcon-E-DesignerRevert; 
  }
}
.ToolbarIcon-E-DesignerZahnrad {
  &:before {
    content: $ToolbarIcon-E-DesignerZahnrad; 
  }
}
.ToolbarIcon-VGuide .path1 {
  &:before {
    content: $ToolbarIcon-VGuide-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-VGuide .path2 {
  &:before {
    content: $ToolbarIcon-VGuide-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-VGuide .path3 {
  &:before {
    content: $ToolbarIcon-VGuide-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-FensterH .path1 {
  &:before {
    content: $ToolbarIcon-FensterH-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-FensterH .path2 {
  &:before {
    content: $ToolbarIcon-FensterH-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-FensterH .path3 {
  &:before {
    content: $ToolbarIcon-FensterH-path3;  
    margin-left: -1em;  
    color: rgb(254, 254, 254);
  }
}
.ToolbarIcon-AddFeld .path1 {
  &:before {
    content: $ToolbarIcon-AddFeld-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-AddFeld .path2 {
  &:before {
    content: $ToolbarIcon-AddFeld-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-Was01 .path1 {
  &:before {
    content: $ToolbarIcon-Was01-path1;  
    color: rgb(153, 153, 153);
  }
}
.ToolbarIcon-Was01 .path2 {
  &:before {
    content: $ToolbarIcon-Was01-path2;  
    margin-left: -1em;  
    color: rgb(51, 51, 51);
  }
}
.ToolbarIcon-Was01 .path3 {
  &:before {
    content: $ToolbarIcon-Was01-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Was01 .path4 {
  &:before {
    content: $ToolbarIcon-Was01-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Was02 .path1 {
  &:before {
    content: $ToolbarIcon-Was02-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-Was02 .path2 {
  &:before {
    content: $ToolbarIcon-Was02-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Was02 .path3 {
  &:before {
    content: $ToolbarIcon-Was02-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DelPoint .path1 {
  &:before {
    content: $ToolbarIcon-DelPoint-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-DelPoint .path2 {
  &:before {
    content: $ToolbarIcon-DelPoint-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-AddPoint .path1 {
  &:before {
    content: $ToolbarIcon-AddPoint-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-AddPoint .path2 {
  &:before {
    content: $ToolbarIcon-AddPoint-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SetAttika .path1 {
  &:before {
    content: $ToolbarIcon-SetAttika-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SetAttika .path2 {
  &:before {
    content: $ToolbarIcon-SetAttika-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-MultiFirst .path1 {
  &:before {
    content: $ToolbarIcon-MultiFirst-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-MultiFirst .path2 {
  &:before {
    content: $ToolbarIcon-MultiFirst-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-MultiTraufe .path1 {
  &:before {
    content: $ToolbarIcon-MultiTraufe-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-MultiTraufe .path2 {
  &:before {
    content: $ToolbarIcon-MultiTraufe-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DetachFlyout .path1 {
  &:before {
    content: $ToolbarIcon-DetachFlyout-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-DetachFlyout .path2 {
  &:before {
    content: $ToolbarIcon-DetachFlyout-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-DetachFlyout .path3 {
  &:before {
    content: $ToolbarIcon-DetachFlyout-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-MoreSpaceV .path1 {
  &:before {
    content: $ToolbarIcon-MoreSpaceV-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-MoreSpaceV .path2 {
  &:before {
    content: $ToolbarIcon-MoreSpaceV-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-MoreSpaceV .path3 {
  &:before {
    content: $ToolbarIcon-MoreSpaceV-path3;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-MoreSpaceV .path4 {
  &:before {
    content: $ToolbarIcon-MoreSpaceV-path4;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-MoreSpaceH .path1 {
  &:before {
    content: $ToolbarIcon-MoreSpaceH-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-MoreSpaceH .path2 {
  &:before {
    content: $ToolbarIcon-MoreSpaceH-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-MoreSpaceH .path3 {
  &:before {
    content: $ToolbarIcon-MoreSpaceH-path3;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-MoreSpaceH .path4 {
  &:before {
    content: $ToolbarIcon-MoreSpaceH-path4;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-InverterBankH .path1 {
  &:before {
    content: $ToolbarIcon-InverterBankH-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-InverterBankH .path2 {
  &:before {
    content: $ToolbarIcon-InverterBankH-path2;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-InverterBankH .path3 {
  &:before {
    content: $ToolbarIcon-InverterBankH-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InverterBankH .path4 {
  &:before {
    content: $ToolbarIcon-InverterBankH-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InverterBankH .path5 {
  &:before {
    content: $ToolbarIcon-InverterBankH-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InverterBankH .path6 {
  &:before {
    content: $ToolbarIcon-InverterBankH-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InverterBankH .path7 {
  &:before {
    content: $ToolbarIcon-InverterBankH-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-SUNSTYLEZiegelZuschneidbar .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelZuschneidbar-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEZiegelZuschneidbar .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelZuschneidbar-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEZiegelZuschneidbar .path3 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelZuschneidbar-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Refresh {
  &:before {
    content: $ToolbarIcon-Refresh; 
  }
}
.ToolbarIcon-Save {
  &:before {
    content: $ToolbarIcon-Save; 
  }
}
.ToolbarIcon-Revert {
  &:before {
    content: $ToolbarIcon-Revert; 
  }
}
.ToolbarIcon-Befestiger .path1 {
  &:before {
    content: $ToolbarIcon-Befestiger-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-Befestiger .path2 {
  &:before {
    content: $ToolbarIcon-Befestiger-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path3 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path4 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path5 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path6 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path6;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path7 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path7;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path8 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path8;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path9 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path9;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path10 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path10;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path11 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path11;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path12 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path12;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path13 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path13;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path14 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path14;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path15 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path15;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path16 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path16;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path17 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path17;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path18 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path18;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path19 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path19;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path20 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path20;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path21 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path21;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path22 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path22;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path23 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path23;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path24 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path24;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path25 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path25;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegel .path26 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegel-path26;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLEZiegel {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegel;     
    color: #666;
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path3 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path4 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path5 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path6 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path6;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path7 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path7;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path8 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path8;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path9 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path9;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path10 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path10;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path11 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path11;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path12 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path12;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path13 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path13;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path14 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path14;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path15 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path15;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path16 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path16;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path17 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path17;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path18 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path18;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path19 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path19;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelUnten .path20 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelUnten-path20;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path3 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path4 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path5 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path6 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path6;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path7 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path7;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path8 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path8;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path9 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path9;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path10 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path10;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path11 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path11;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path12 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path12;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path13 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path13;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path14 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path14;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path15 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path15;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path16 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path16;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path17 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path17;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path18 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path18;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path19 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path19;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelOben .path20 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelOben-path20;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path3 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path4 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path5 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path6 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path6;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path7 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path7;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path8 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path8;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path9 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path9;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path10 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path10;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path11 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path11;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path12 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path12;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path13 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path13;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path14 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path14;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path15 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path15;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path16 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path16;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path17 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path17;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path18 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path18;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path19 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path19;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path20 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path20;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelRechts .path21 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelRechts-path21;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path3 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path4 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path5 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path6 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path6;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path7 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path7;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path8 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path8;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path9 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path9;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path10 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path10;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path11 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path11;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path12 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path12;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path13 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path13;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path14 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path14;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path15 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path15;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path16 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path16;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path17 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path17;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path18 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path18;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path19 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path19;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path20 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path20;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLESolarziegelLinks .path21 {
  &:before {
    content: $ToolbarIcon-SUNSTYLESolarziegelLinks-path21;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SUNSTYLEZiegelUnten .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelUnten-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEZiegelUnten .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelUnten-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEZiegelOben .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelOben-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEZiegelOben .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelOben-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEZiegelLinks .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelLinks-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEZiegelLinks .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelLinks-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEZiegelRechts .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelRechts-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEZiegelRechts .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelRechts-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEEckeGrossUntenLinks .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeGrossUntenLinks-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEEckeGrossUntenLinks .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeGrossUntenLinks-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEEckeGrossUntenRechts .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeGrossUntenRechts-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEEckeGrossUntenRechts .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeGrossUntenRechts-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEEckeGrossObenLinks .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeGrossObenLinks-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEEckeGrossObenLinks .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeGrossObenLinks-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEEckeGrossObenRechts .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeGrossObenRechts-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEEckeGrossObenRechts .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeGrossObenRechts-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEEckeKleinUntenLinks .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeKleinUntenLinks-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEEckeKleinUntenLinks .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeKleinUntenLinks-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEEckeKlainUntenRechts .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeKlainUntenRechts-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEEckeKlainUntenRechts .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeKlainUntenRechts-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEEckeKleinObenRechts .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeKleinObenRechts-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEEckeKleinObenRechts .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeKleinObenRechts-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEEckeKleinObenLinks .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeKleinObenLinks-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEEckeKleinObenLinks .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEEckeKleinObenLinks-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEZiegelUntenKlein .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelUntenKlein-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEZiegelUntenKlein .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelUntenKlein-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SUNSTYLEZiegelObenKlein .path1 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelObenKlein-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-SUNSTYLEZiegelObenKlein .path2 {
  &:before {
    content: $ToolbarIcon-SUNSTYLEZiegelObenKlein-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-InverterBankV .path1 {
  &:before {
    content: $ToolbarIcon-InverterBankV-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-InverterBankV .path2 {
  &:before {
    content: $ToolbarIcon-InverterBankV-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-InverterBankV .path3 {
  &:before {
    content: $ToolbarIcon-InverterBankV-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InverterBankV .path4 {
  &:before {
    content: $ToolbarIcon-InverterBankV-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InverterBankV .path5 {
  &:before {
    content: $ToolbarIcon-InverterBankV-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InverterBankV .path6 {
  &:before {
    content: $ToolbarIcon-InverterBankV-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InverterBankV .path7 {
  &:before {
    content: $ToolbarIcon-InverterBankV-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-Pen {
  &:before {
    content: $ToolbarIcon-Pen;     
    color: #666;
  }
}
.ToolbarIcon-ACricle .path1 {
  &:before {
    content: $ToolbarIcon-ACricle-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-ACricle .path2 {
  &:before {
    content: $ToolbarIcon-ACricle-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Tools {
  &:before {
    content: $ToolbarIcon-Tools;     
    color: #666;
  }
}
.ToolbarIcon-Cut .path1 {
  &:before {
    content: $ToolbarIcon-Cut-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Cut .path2 {
  &:before {
    content: $ToolbarIcon-Cut-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Cut .path3 {
  &:before {
    content: $ToolbarIcon-Cut-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Cut .path4 {
  &:before {
    content: $ToolbarIcon-Cut-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Cut .path5 {
  &:before {
    content: $ToolbarIcon-Cut-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Cut .path6 {
  &:before {
    content: $ToolbarIcon-Cut-path6;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Cut .path7 {
  &:before {
    content: $ToolbarIcon-Cut-path7;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-Cut .path8 {
  &:before {
    content: $ToolbarIcon-Cut-path8;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-KabelErsetzen .path1 {
  &:before {
    content: $ToolbarIcon-KabelErsetzen-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-KabelErsetzen .path2 {
  &:before {
    content: $ToolbarIcon-KabelErsetzen-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-KabelErsetzen .path3 {
  &:before {
    content: $ToolbarIcon-KabelErsetzen-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-KabelErsetzen .path4 {
  &:before {
    content: $ToolbarIcon-KabelErsetzen-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-PolylinienAnzeigen .path1 {
  &:before {
    content: $ToolbarIcon-PolylinienAnzeigen-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-PolylinienAnzeigen .path2 {
  &:before {
    content: $ToolbarIcon-PolylinienAnzeigen-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-PolylinienAnzeigen .path3 {
  &:before {
    content: $ToolbarIcon-PolylinienAnzeigen-path3;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-KabelGenerieren {
  &:before {
    content: $ToolbarIcon-KabelGenerieren; 
  }
}
.ToolbarIcon-ProfilTool .path1 {
  &:before {
    content: $ToolbarIcon-ProfilTool-path1;  
    color: rgb(28, 28, 27);
  }
}
.ToolbarIcon-ProfilTool .path2 {
  &:before {
    content: $ToolbarIcon-ProfilTool-path2;  
    margin-left: -1em;  
    color: rgb(153, 153, 153);
  }
}
.ToolbarIcon-ErdungsTool {
  &:before {
    content: $ToolbarIcon-ErdungsTool;     
    color: #666;
  }
}
.ToolbarIcon-VerstringungTool .path1 {
  &:before {
    content: $ToolbarIcon-VerstringungTool-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-VerstringungTool .path2 {
  &:before {
    content: $ToolbarIcon-VerstringungTool-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-VerstringungTool .path3 {
  &:before {
    content: $ToolbarIcon-VerstringungTool-path3;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-InterferenceObj .path1 {
  &:before {
    content: $ToolbarIcon-InterferenceObj-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-InterferenceObj .path2 {
  &:before {
    content: $ToolbarIcon-InterferenceObj-path2;  
    margin-left: -1.017578125em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Gaube .path1 {
  &:before {
    content: $ToolbarIcon-Gaube-path1;  
    color: rgb(153, 153, 153);
  }
}
.ToolbarIcon-Gaube .path2 {
  &:before {
    content: $ToolbarIcon-Gaube-path2;  
    margin-left: -1em;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-Gaube .path3 {
  &:before {
    content: $ToolbarIcon-Gaube-path3;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-Gaube .path4 {
  &:before {
    content: $ToolbarIcon-Gaube-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-Gaube .path5 {
  &:before {
    content: $ToolbarIcon-Gaube-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-Gaube .path6 {
  &:before {
    content: $ToolbarIcon-Gaube-path6;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-KabelKanal .path1 {
  &:before {
    content: $ToolbarIcon-KabelKanal-path1;  
    color: rgb(28, 28, 27);
  }
}
.ToolbarIcon-KabelKanal .path2 {
  &:before {
    content: $ToolbarIcon-KabelKanal-path2;  
    margin-left: -1em;  
    color: rgb(153, 153, 153);
  }
}
.ToolbarIcon-NoDocking .path1 {
  &:before {
    content: $ToolbarIcon-NoDocking-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-NoDocking .path2 {
  &:before {
    content: $ToolbarIcon-NoDocking-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Docking {
  &:before {
    content: $ToolbarIcon-Docking;     
    color: #666;
  }
}
.ToolbarIcon-StringTool .path1 {
  &:before {
    content: $ToolbarIcon-StringTool-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-StringTool .path2 {
  &:before {
    content: $ToolbarIcon-StringTool-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-StringTool .path3 {
  &:before {
    content: $ToolbarIcon-StringTool-path3;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-StringTool .path4 {
  &:before {
    content: $ToolbarIcon-StringTool-path4;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-RamSouth .path1 {
  &:before {
    content: $ToolbarIcon-RamSouth-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-RamSouth .path2 {
  &:before {
    content: $ToolbarIcon-RamSouth-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-RamWest .path1 {
  &:before {
    content: $ToolbarIcon-RamWest-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-RamWest .path2 {
  &:before {
    content: $ToolbarIcon-RamWest-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-RamNorth .path1 {
  &:before {
    content: $ToolbarIcon-RamNorth-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-RamNorth .path2 {
  &:before {
    content: $ToolbarIcon-RamNorth-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-RamEast .path1 {
  &:before {
    content: $ToolbarIcon-RamEast-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-RamEast .path2 {
  &:before {
    content: $ToolbarIcon-RamEast-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ShowHideStringList .path1 {
  &:before {
    content: $ToolbarIcon-ShowHideStringList-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-ShowHideStringList .path2 {
  &:before {
    content: $ToolbarIcon-ShowHideStringList-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ShowHideStringList .path3 {
  &:before {
    content: $ToolbarIcon-ShowHideStringList-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ShowHideStringList .path4 {
  &:before {
    content: $ToolbarIcon-ShowHideStringList-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ShowHideStringList .path5 {
  &:before {
    content: $ToolbarIcon-ShowHideStringList-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-DachSeiteMessen .path1 {
  &:before {
    content: $ToolbarIcon-DachSeiteMessen-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-DachSeiteMessen .path2 {
  &:before {
    content: $ToolbarIcon-DachSeiteMessen-path2;  
    margin-left: -1em;  
    color: rgb(153, 153, 153);
  }
}
.ToolbarIcon-DachSeiteMessen .path3 {
  &:before {
    content: $ToolbarIcon-DachSeiteMessen-path3;  
    margin-left: -1em;  
    color: rgb(153, 153, 153);
  }
}
.ToolbarIcon-DachSeiteMessen .path4 {
  &:before {
    content: $ToolbarIcon-DachSeiteMessen-path4;  
    margin-left: -1em;  
    color: rgb(153, 153, 153);
  }
}
.ToolbarIcon-DachSeiteMessen .path5 {
  &:before {
    content: $ToolbarIcon-DachSeiteMessen-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DachSeiteMessen .path6 {
  &:before {
    content: $ToolbarIcon-DachSeiteMessen-path6;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DachSeiteMessen .path7 {
  &:before {
    content: $ToolbarIcon-DachSeiteMessen-path7;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DachSeiteMessen .path8 {
  &:before {
    content: $ToolbarIcon-DachSeiteMessen-path8;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DachSeiteMessen .path9 {
  &:before {
    content: $ToolbarIcon-DachSeiteMessen-path9;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-SelectArea .path1 {
  &:before {
    content: $ToolbarIcon-SelectArea-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-SelectArea .path2 {
  &:before {
    content: $ToolbarIcon-SelectArea-path2;  
    margin-left: -1.017578125em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DrawSingleCurve .path1 {
  &:before {
    content: $ToolbarIcon-DrawSingleCurve-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-DrawSingleCurve .path2 {
  &:before {
    content: $ToolbarIcon-DrawSingleCurve-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DrawSinglePoly .path1 {
  &:before {
    content: $ToolbarIcon-DrawSinglePoly-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-DrawSinglePoly .path2 {
  &:before {
    content: $ToolbarIcon-DrawSinglePoly-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Draw3Eck .path1 {
  &:before {
    content: $ToolbarIcon-Draw3Eck-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-Draw3Eck .path2 {
  &:before {
    content: $ToolbarIcon-Draw3Eck-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DrawRect .path1 {
  &:before {
    content: $ToolbarIcon-DrawRect-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-DrawRect .path2 {
  &:before {
    content: $ToolbarIcon-DrawRect-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DrawCircle .path1 {
  &:before {
    content: $ToolbarIcon-DrawCircle-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-DrawCircle .path2 {
  &:before {
    content: $ToolbarIcon-DrawCircle-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DrawPloy .path1 {
  &:before {
    content: $ToolbarIcon-DrawPloy-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-DrawPloy .path2 {
  &:before {
    content: $ToolbarIcon-DrawPloy-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DelVerbindung .path1 {
  &:before {
    content: $ToolbarIcon-DelVerbindung-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-DelVerbindung .path2 {
  &:before {
    content: $ToolbarIcon-DelVerbindung-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Verbindung {
  &:before {
    content: $ToolbarIcon-Verbindung;     
    color: #666;
  }
}
.ToolbarIcon-Durchdringung .path1 {
  &:before {
    content: $ToolbarIcon-Durchdringung-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-Durchdringung .path2 {
  &:before {
    content: $ToolbarIcon-Durchdringung-path2;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-DelFeld .path1 {
  &:before {
    content: $ToolbarIcon-DelFeld-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DelFeld .path2 {
  &:before {
    content: $ToolbarIcon-DelFeld-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DelFeld .path3 {
  &:before {
    content: $ToolbarIcon-DelFeld-path3;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-ModH6Ext .path1 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModH6Ext .path2 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModH6Ext .path3 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path4 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path5 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path6 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path7 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path8 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path9 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path10 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path11 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path11;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path12 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path12;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path13 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path13;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path14 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path14;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path15 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path15;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path16 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path16;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path17 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path17;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path18 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path18;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path19 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path19;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6Ext .path20 {
  &:before {
    content: $ToolbarIcon-ModH6Ext-path20;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH5 .path1 {
  &:before {
    content: $ToolbarIcon-ModH5-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModH5 .path2 {
  &:before {
    content: $ToolbarIcon-ModH5-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModH5 .path3 {
  &:before {
    content: $ToolbarIcon-ModH5-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path1 {
  &:before {
    content: $ToolbarIcon-ModH6-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModH6 .path2 {
  &:before {
    content: $ToolbarIcon-ModH6-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModH6 .path3 {
  &:before {
    content: $ToolbarIcon-ModH6-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path4 {
  &:before {
    content: $ToolbarIcon-ModH6-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path5 {
  &:before {
    content: $ToolbarIcon-ModH6-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path6 {
  &:before {
    content: $ToolbarIcon-ModH6-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path7 {
  &:before {
    content: $ToolbarIcon-ModH6-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path8 {
  &:before {
    content: $ToolbarIcon-ModH6-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path9 {
  &:before {
    content: $ToolbarIcon-ModH6-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path10 {
  &:before {
    content: $ToolbarIcon-ModH6-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path11 {
  &:before {
    content: $ToolbarIcon-ModH6-path11;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path12 {
  &:before {
    content: $ToolbarIcon-ModH6-path12;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path13 {
  &:before {
    content: $ToolbarIcon-ModH6-path13;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path14 {
  &:before {
    content: $ToolbarIcon-ModH6-path14;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path15 {
  &:before {
    content: $ToolbarIcon-ModH6-path15;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path16 {
  &:before {
    content: $ToolbarIcon-ModH6-path16;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path17 {
  &:before {
    content: $ToolbarIcon-ModH6-path17;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path18 {
  &:before {
    content: $ToolbarIcon-ModH6-path18;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path19 {
  &:before {
    content: $ToolbarIcon-ModH6-path19;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH6 .path20 {
  &:before {
    content: $ToolbarIcon-ModH6-path20;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4Ext .path1 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModH4Ext .path2 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModH4Ext .path3 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4Ext .path4 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4Ext .path5 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4Ext .path6 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4Ext .path7 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4Ext .path8 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4Ext .path9 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4Ext .path10 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4Ext .path11 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path11;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4Ext .path12 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path12;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4Ext .path13 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path13;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4Ext .path14 {
  &:before {
    content: $ToolbarIcon-ModH4Ext-path14;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4 .path1 {
  &:before {
    content: $ToolbarIcon-ModH4-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModH4 .path2 {
  &:before {
    content: $ToolbarIcon-ModH4-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModH4 .path3 {
  &:before {
    content: $ToolbarIcon-ModH4-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4 .path4 {
  &:before {
    content: $ToolbarIcon-ModH4-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4 .path5 {
  &:before {
    content: $ToolbarIcon-ModH4-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4 .path6 {
  &:before {
    content: $ToolbarIcon-ModH4-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4 .path7 {
  &:before {
    content: $ToolbarIcon-ModH4-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4 .path8 {
  &:before {
    content: $ToolbarIcon-ModH4-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4 .path9 {
  &:before {
    content: $ToolbarIcon-ModH4-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4 .path10 {
  &:before {
    content: $ToolbarIcon-ModH4-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4 .path11 {
  &:before {
    content: $ToolbarIcon-ModH4-path11;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4 .path12 {
  &:before {
    content: $ToolbarIcon-ModH4-path12;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4 .path13 {
  &:before {
    content: $ToolbarIcon-ModH4-path13;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH4 .path14 {
  &:before {
    content: $ToolbarIcon-ModH4-path14;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3Ext .path1 {
  &:before {
    content: $ToolbarIcon-ModH3Ext-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModH3Ext .path2 {
  &:before {
    content: $ToolbarIcon-ModH3Ext-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModH3Ext .path3 {
  &:before {
    content: $ToolbarIcon-ModH3Ext-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3Ext .path4 {
  &:before {
    content: $ToolbarIcon-ModH3Ext-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3Ext .path5 {
  &:before {
    content: $ToolbarIcon-ModH3Ext-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3Ext .path6 {
  &:before {
    content: $ToolbarIcon-ModH3Ext-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3Ext .path7 {
  &:before {
    content: $ToolbarIcon-ModH3Ext-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3Ext .path8 {
  &:before {
    content: $ToolbarIcon-ModH3Ext-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3Ext .path9 {
  &:before {
    content: $ToolbarIcon-ModH3Ext-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3Ext .path10 {
  &:before {
    content: $ToolbarIcon-ModH3Ext-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3Ext .path11 {
  &:before {
    content: $ToolbarIcon-ModH3Ext-path11;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3 .path1 {
  &:before {
    content: $ToolbarIcon-ModH3-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModH3 .path2 {
  &:before {
    content: $ToolbarIcon-ModH3-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModH3 .path3 {
  &:before {
    content: $ToolbarIcon-ModH3-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3 .path4 {
  &:before {
    content: $ToolbarIcon-ModH3-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3 .path5 {
  &:before {
    content: $ToolbarIcon-ModH3-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3 .path6 {
  &:before {
    content: $ToolbarIcon-ModH3-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3 .path7 {
  &:before {
    content: $ToolbarIcon-ModH3-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3 .path8 {
  &:before {
    content: $ToolbarIcon-ModH3-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3 .path9 {
  &:before {
    content: $ToolbarIcon-ModH3-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3 .path10 {
  &:before {
    content: $ToolbarIcon-ModH3-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH3 .path11 {
  &:before {
    content: $ToolbarIcon-ModH3-path11;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH2Ext .path1 {
  &:before {
    content: $ToolbarIcon-ModH2Ext-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModH2Ext .path2 {
  &:before {
    content: $ToolbarIcon-ModH2Ext-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModH2Ext .path3 {
  &:before {
    content: $ToolbarIcon-ModH2Ext-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH2Ext .path4 {
  &:before {
    content: $ToolbarIcon-ModH2Ext-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH2Ext .path5 {
  &:before {
    content: $ToolbarIcon-ModH2Ext-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH2Ext .path6 {
  &:before {
    content: $ToolbarIcon-ModH2Ext-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH2Ext .path7 {
  &:before {
    content: $ToolbarIcon-ModH2Ext-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModH2Ext .path8 {
  &:before {
    content: $ToolbarIcon-ModH2Ext-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path1 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModV6Ext .path2 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModV6Ext .path3 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path4 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path5 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path6 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path7 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path8 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path9 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path10 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path11 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path11;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path12 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path12;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path13 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path13;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path14 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path14;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path15 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path15;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path16 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path16;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path17 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path17;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path18 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path18;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path19 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path19;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6Ext .path20 {
  &:before {
    content: $ToolbarIcon-ModV6Ext-path20;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path1 {
  &:before {
    content: $ToolbarIcon-ModV6-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModV6 .path2 {
  &:before {
    content: $ToolbarIcon-ModV6-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModV6 .path3 {
  &:before {
    content: $ToolbarIcon-ModV6-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path4 {
  &:before {
    content: $ToolbarIcon-ModV6-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path5 {
  &:before {
    content: $ToolbarIcon-ModV6-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path6 {
  &:before {
    content: $ToolbarIcon-ModV6-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path7 {
  &:before {
    content: $ToolbarIcon-ModV6-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path8 {
  &:before {
    content: $ToolbarIcon-ModV6-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path9 {
  &:before {
    content: $ToolbarIcon-ModV6-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path10 {
  &:before {
    content: $ToolbarIcon-ModV6-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path11 {
  &:before {
    content: $ToolbarIcon-ModV6-path11;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path12 {
  &:before {
    content: $ToolbarIcon-ModV6-path12;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path13 {
  &:before {
    content: $ToolbarIcon-ModV6-path13;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path14 {
  &:before {
    content: $ToolbarIcon-ModV6-path14;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path15 {
  &:before {
    content: $ToolbarIcon-ModV6-path15;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path16 {
  &:before {
    content: $ToolbarIcon-ModV6-path16;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path17 {
  &:before {
    content: $ToolbarIcon-ModV6-path17;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path18 {
  &:before {
    content: $ToolbarIcon-ModV6-path18;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path19 {
  &:before {
    content: $ToolbarIcon-ModV6-path19;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV6 .path20 {
  &:before {
    content: $ToolbarIcon-ModV6-path20;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV5 .path1 {
  &:before {
    content: $ToolbarIcon-ModV5-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModV5 .path2 {
  &:before {
    content: $ToolbarIcon-ModV5-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModV5 .path3 {
  &:before {
    content: $ToolbarIcon-ModV5-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV4 .path1 {
  &:before {
    content: $ToolbarIcon-ModV4-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModV4 .path2 {
  &:before {
    content: $ToolbarIcon-ModV4-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModV4 .path3 {
  &:before {
    content: $ToolbarIcon-ModV4-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV4 .path4 {
  &:before {
    content: $ToolbarIcon-ModV4-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV4 .path5 {
  &:before {
    content: $ToolbarIcon-ModV4-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV4 .path6 {
  &:before {
    content: $ToolbarIcon-ModV4-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV4 .path7 {
  &:before {
    content: $ToolbarIcon-ModV4-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV4 .path8 {
  &:before {
    content: $ToolbarIcon-ModV4-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV4 .path9 {
  &:before {
    content: $ToolbarIcon-ModV4-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV4 .path10 {
  &:before {
    content: $ToolbarIcon-ModV4-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV4 .path11 {
  &:before {
    content: $ToolbarIcon-ModV4-path11;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV4 .path12 {
  &:before {
    content: $ToolbarIcon-ModV4-path12;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV4 .path13 {
  &:before {
    content: $ToolbarIcon-ModV4-path13;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV4 .path14 {
  &:before {
    content: $ToolbarIcon-ModV4-path14;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3 .path1 {
  &:before {
    content: $ToolbarIcon-modV3-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-modV3 .path2 {
  &:before {
    content: $ToolbarIcon-modV3-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-modV3 .path3 {
  &:before {
    content: $ToolbarIcon-modV3-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3 .path4 {
  &:before {
    content: $ToolbarIcon-modV3-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3 .path5 {
  &:before {
    content: $ToolbarIcon-modV3-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3 .path6 {
  &:before {
    content: $ToolbarIcon-modV3-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3 .path7 {
  &:before {
    content: $ToolbarIcon-modV3-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3 .path8 {
  &:before {
    content: $ToolbarIcon-modV3-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3 .path9 {
  &:before {
    content: $ToolbarIcon-modV3-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3 .path10 {
  &:before {
    content: $ToolbarIcon-modV3-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3 .path11 {
  &:before {
    content: $ToolbarIcon-modV3-path11;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3Ext .path1 {
  &:before {
    content: $ToolbarIcon-modV3Ext-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-modV3Ext .path2 {
  &:before {
    content: $ToolbarIcon-modV3Ext-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-modV3Ext .path3 {
  &:before {
    content: $ToolbarIcon-modV3Ext-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3Ext .path4 {
  &:before {
    content: $ToolbarIcon-modV3Ext-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3Ext .path5 {
  &:before {
    content: $ToolbarIcon-modV3Ext-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3Ext .path6 {
  &:before {
    content: $ToolbarIcon-modV3Ext-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3Ext .path7 {
  &:before {
    content: $ToolbarIcon-modV3Ext-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3Ext .path8 {
  &:before {
    content: $ToolbarIcon-modV3Ext-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3Ext .path9 {
  &:before {
    content: $ToolbarIcon-modV3Ext-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3Ext .path10 {
  &:before {
    content: $ToolbarIcon-modV3Ext-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-modV3Ext .path11 {
  &:before {
    content: $ToolbarIcon-modV3Ext-path11;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV2Ext .path1 {
  &:before {
    content: $ToolbarIcon-ModV2Ext-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModV2Ext .path2 {
  &:before {
    content: $ToolbarIcon-ModV2Ext-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModV2Ext .path3 {
  &:before {
    content: $ToolbarIcon-ModV2Ext-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV2Ext .path4 {
  &:before {
    content: $ToolbarIcon-ModV2Ext-path4;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV2Ext .path5 {
  &:before {
    content: $ToolbarIcon-ModV2Ext-path5;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV2Ext .path6 {
  &:before {
    content: $ToolbarIcon-ModV2Ext-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV2Ext .path7 {
  &:before {
    content: $ToolbarIcon-ModV2Ext-path7;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV2Ext .path8 {
  &:before {
    content: $ToolbarIcon-ModV2Ext-path8;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV2Ext .path9 {
  &:before {
    content: $ToolbarIcon-ModV2Ext-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV2Ext .path10 {
  &:before {
    content: $ToolbarIcon-ModV2Ext-path10;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModV2Ext .path11 {
  &:before {
    content: $ToolbarIcon-ModV2Ext-path11;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-Traufe .path1 {
  &:before {
    content: $ToolbarIcon-Traufe-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Traufe .path2 {
  &:before {
    content: $ToolbarIcon-Traufe-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-Traufe .path3 {
  &:before {
    content: $ToolbarIcon-Traufe-path3;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-First .path1 {
  &:before {
    content: $ToolbarIcon-First-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-First .path2 {
  &:before {
    content: $ToolbarIcon-First-path2;  
    margin-left: -1.017578125em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-First .path3 {
  &:before {
    content: $ToolbarIcon-First-path3;  
    margin-left: -1.017578125em;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-ZMessen .path1 {
  &:before {
    content: $ToolbarIcon-ZMessen-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-ZMessen .path2 {
  &:before {
    content: $ToolbarIcon-ZMessen-path2;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-MessenFrei .path1 {
  &:before {
    content: $ToolbarIcon-MessenFrei-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-MessenFrei .path2 {
  &:before {
    content: $ToolbarIcon-MessenFrei-path2;  
    margin-left: -1em;  
    color: rgb(128, 128, 128);
  }
}
.ToolbarIcon-MessenZFrei {
  &:before {
    content: $ToolbarIcon-MessenZFrei;     
    color: #666;
  }
}
.ToolbarIcon-Grad {
  &:before {
    content: $ToolbarIcon-Grad;     
    color: #666;
  }
}
.ToolbarIcon-ThermalH .path1 {
  &:before {
    content: $ToolbarIcon-ThermalH-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ThermalH .path2 {
  &:before {
    content: $ToolbarIcon-ThermalH-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ThermalH .path3 {
  &:before {
    content: $ToolbarIcon-ThermalH-path3;  
    margin-left: -1em;  
    color: rgb(254, 254, 254);
  }
}
.ToolbarIcon-FensterHLeer .path1 {
  &:before {
    content: $ToolbarIcon-FensterHLeer-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-FensterHLeer .path2 {
  &:before {
    content: $ToolbarIcon-FensterHLeer-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ThermalV .path1 {
  &:before {
    content: $ToolbarIcon-ThermalV-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ThermalV .path2 {
  &:before {
    content: $ToolbarIcon-ThermalV-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ThermalV .path3 {
  &:before {
    content: $ToolbarIcon-ThermalV-path3;  
    margin-left: -1em;  
    color: rgb(254, 254, 254);
  }
}
.ToolbarIcon-FensterV .path1 {
  &:before {
    content: $ToolbarIcon-FensterV-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-FensterV .path2 {
  &:before {
    content: $ToolbarIcon-FensterV-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-FensterV .path3 {
  &:before {
    content: $ToolbarIcon-FensterV-path3;  
    margin-left: -1em;  
    color: rgb(254, 254, 254);
  }
}
.ToolbarIcon-FensterV .path4 {
  &:before {
    content: $ToolbarIcon-FensterV-path4;  
    margin-left: -1em;  
    color: rgb(254, 254, 254);
  }
}
.ToolbarIcon-FensterV .path5 {
  &:before {
    content: $ToolbarIcon-FensterV-path5;  
    margin-left: -1em;  
    color: rgb(254, 254, 254);
  }
}
.ToolbarIcon-FensterV .path6 {
  &:before {
    content: $ToolbarIcon-FensterV-path6;  
    margin-left: -1em;  
    color: rgb(254, 254, 254);
  }
}
.ToolbarIcon-FensterVLeer .path1 {
  &:before {
    content: $ToolbarIcon-FensterVLeer-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-FensterVLeer .path2 {
  &:before {
    content: $ToolbarIcon-FensterVLeer-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-Delete .path1 {
  &:before {
    content: $ToolbarIcon-Delete-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Delete .path2 {
  &:before {
    content: $ToolbarIcon-Delete-path2;  
    margin-left: -1em;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-DelHGuide .path1 {
  &:before {
    content: $ToolbarIcon-DelHGuide-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-DelHGuide .path2 {
  &:before {
    content: $ToolbarIcon-DelHGuide-path2;  
    margin-left: -1em;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-DelHGuide .path3 {
  &:before {
    content: $ToolbarIcon-DelHGuide-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DelHGuide .path4 {
  &:before {
    content: $ToolbarIcon-DelHGuide-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-HGuide .path1 {
  &:before {
    content: $ToolbarIcon-HGuide-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-HGuide .path2 {
  &:before {
    content: $ToolbarIcon-HGuide-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-DelVGuide .path1 {
  &:before {
    content: $ToolbarIcon-DelVGuide-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-DelVGuide .path2 {
  &:before {
    content: $ToolbarIcon-DelVGuide-path2;  
    margin-left: -1em;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-DelVGuide .path3 {
  &:before {
    content: $ToolbarIcon-DelVGuide-path3;  
    margin-left: -1em;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-DelVGuide .path4 {
  &:before {
    content: $ToolbarIcon-DelVGuide-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DelVGuide .path5 {
  &:before {
    content: $ToolbarIcon-DelVGuide-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DelMod .path1 {
  &:before {
    content: $ToolbarIcon-DelMod-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-DelMod .path2 {
  &:before {
    content: $ToolbarIcon-DelMod-path2;  
    margin-left: -1em;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-DelMod .path3 {
  &:before {
    content: $ToolbarIcon-DelMod-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-DelMod .path4 {
  &:before {
    content: $ToolbarIcon-DelMod-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-DelMod .path5 {
  &:before {
    content: $ToolbarIcon-DelMod-path5;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Frage {
  &:before {
    content: $ToolbarIcon-Frage; 
  }
}
.ToolbarIcon-NoMod .path1 {
  &:before {
    content: $ToolbarIcon-NoMod-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-NoMod .path2 {
  &:before {
    content: $ToolbarIcon-NoMod-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-NoMod .path3 {
  &:before {
    content: $ToolbarIcon-NoMod-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-NoMod .path4 {
  &:before {
    content: $ToolbarIcon-NoMod-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-ModTransparent .path1 {
  &:before {
    content: $ToolbarIcon-ModTransparent-path1;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-ModTransparent .path2 {
  &:before {
    content: $ToolbarIcon-ModTransparent-path2;  
    margin-left: -1em;  
    color: rgb(230, 230, 230);
  }
}
.ToolbarIcon-ModTransparent .path3 {
  &:before {
    content: $ToolbarIcon-ModTransparent-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModTransparent .path4 {
  &:before {
    content: $ToolbarIcon-ModTransparent-path4;  
    margin-left: -1em;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModTransparent .path5 {
  &:before {
    content: $ToolbarIcon-ModTransparent-path5;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModTransparent .path6 {
  &:before {
    content: $ToolbarIcon-ModTransparent-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-Messen {
  &:before {
    content: $ToolbarIcon-Messen;     
    color: #808080;
  }
}
.ToolbarIcon-Drehen .path1 {
  &:before {
    content: $ToolbarIcon-Drehen-path1;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Drehen .path2 {
  &:before {
    content: $ToolbarIcon-Drehen-path2;  
    margin-left: -1em;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-Drehen .path3 {
  &:before {
    content: $ToolbarIcon-Drehen-path3;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-Cube {
  &:before {
    content: $ToolbarIcon-Cube; 
  }
}
.ToolbarIcon-Lupe {
  &:before {
    content: $ToolbarIcon-Lupe; 
  }
}
.ToolbarIcon-HandMove {
  &:before {
    content: $ToolbarIcon-HandMove; 
  }
}
.ToolbarIcon-HandPoint {
  &:before {
    content: $ToolbarIcon-HandPoint; 
  }
}
.ToolbarIcon-Hand {
  &:before {
    content: $ToolbarIcon-Hand; 
  }
}
.ToolbarIcon-Hamburger {
  &:before {
    content: $ToolbarIcon-Hamburger;     
    color: #808080;
  }
}
.ToolbarIcon-Verbot {
  &:before {
    content: $ToolbarIcon-Verbot; 
  }
}
.ToolbarIcon-ModElevation .path1 {
  &:before {
    content: $ToolbarIcon-ModElevation-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModElevation .path2 {
  &:before {
    content: $ToolbarIcon-ModElevation-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModElevation .path3 {
  &:before {
    content: $ToolbarIcon-ModElevation-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModElevation .path4 {
  &:before {
    content: $ToolbarIcon-ModElevation-path4;  
    margin-left: -1em;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModElevation .path5 {
  &:before {
    content: $ToolbarIcon-ModElevation-path5;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModElevation .path6 {
  &:before {
    content: $ToolbarIcon-ModElevation-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InsertCopy .path1 {
  &:before {
    content: $ToolbarIcon-InsertCopy-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-InsertCopy .path2 {
  &:before {
    content: $ToolbarIcon-InsertCopy-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-InsertCopy .path3 {
  &:before {
    content: $ToolbarIcon-InsertCopy-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InsertCopy .path4 {
  &:before {
    content: $ToolbarIcon-InsertCopy-path4;  
    margin-left: -1em;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-InsertCopy .path5 {
  &:before {
    content: $ToolbarIcon-InsertCopy-path5;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-InsertCopy .path6 {
  &:before {
    content: $ToolbarIcon-InsertCopy-path6;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InsertCopy .path7 {
  &:before {
    content: $ToolbarIcon-InsertCopy-path7;  
    margin-left: -1em;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-InsertCopy .path8 {
  &:before {
    content: $ToolbarIcon-InsertCopy-path8;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-InsertCopy .path9 {
  &:before {
    content: $ToolbarIcon-InsertCopy-path9;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InsertCopy .path10 {
  &:before {
    content: $ToolbarIcon-InsertCopy-path10;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-InsertSelection .path1 {
  &:before {
    content: $ToolbarIcon-InsertSelection-path1;  
    color: rgb(102, 102, 102);
  }
}
.ToolbarIcon-InsertSelection .path2 {
  &:before {
    content: $ToolbarIcon-InsertSelection-path2;  
    margin-left: -1.017578125em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-InsertSingleMod .path1 {
  &:before {
    content: $ToolbarIcon-InsertSingleMod-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-InsertSingleMod .path2 {
  &:before {
    content: $ToolbarIcon-InsertSingleMod-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-InsertSingleMod .path3 {
  &:before {
    content: $ToolbarIcon-InsertSingleMod-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-InsertSingleMod .path4 {
  &:before {
    content: $ToolbarIcon-InsertSingleMod-path4;  
    margin-left: -1em;  
    color: rgb(0, 0, 0);
  }
}
.ToolbarIcon-ModuleH .path1 {
  &:before {
    content: $ToolbarIcon-ModuleH-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModuleH .path2 {
  &:before {
    content: $ToolbarIcon-ModuleH-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModuleH .path3 {
  &:before {
    content: $ToolbarIcon-ModuleH-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-ModulV .path1 {
  &:before {
    content: $ToolbarIcon-ModulV-path1;  
    color: rgb(204, 204, 204);
  }
}
.ToolbarIcon-ModulV .path2 {
  &:before {
    content: $ToolbarIcon-ModulV-path2;  
    margin-left: -1em;  
    color: rgb(77, 77, 77);
  }
}
.ToolbarIcon-ModulV .path3 {
  &:before {
    content: $ToolbarIcon-ModulV-path3;  
    margin-left: -1em;  
    color: rgb(255, 255, 255);
  }
}
.ToolbarIcon-select {
  &:before {
    content: $ToolbarIcon-select; 
  }
}

