/*@import "variables";*/

/* BEGIN SweetAlert success icon */

.sa {
    /*width: 120px;
    height: 120px;*/
    /*padding: 26px;*/
    /*background-color: #fff;*/

    &-success {
        border-radius: 50%;
        border: 4px solid #A5DC86;
        box-sizing: content-box;
        height: 80px;
        padding: 0;
        position: relative;
        /*background-color: #fff;*/
        width: 80px;

        &:after, &:before {
            /*background: #fff;*/
            content: '';
            height: 120px;
            position: absolute;
            transform: rotate(45deg);
            width: 60px;
        }

        &:before {
            border-radius: 40px 0 0 40px;
            width: 26px;
            height: 80px;
            top: -17px;
            left: 5px;
            transform-origin: 60px 60px;
            transform: rotate(-45deg);
        }

        &:after {
            border-radius: 0 120px 120px 0;
            left: 30px;
            top: -11px;
            transform-origin: 0 60px;
            transform: rotate(-45deg);
            animation: rotatePlaceholder 4.25s ease-in;
        }

        &-placeholder {
            border-radius: 50%;
            border: 4px solid rgba(165, 220, 134, 0.25);
            box-sizing: content-box;
            height: 80px;
            left: -4px;
            position: absolute;
            top: -4px;
            width: 80px;
            z-index: 2;
        }

        &-fix {
            /*background-color: #fff;*/
            height: 90px;
            left: 28px;
            position: absolute;
            top: 8px;
            transform: rotate(-45deg);
            width: 5px;
            z-index: 1;
        }

        &-tip, &-long {
            background-color: #A5DC86;
            border-radius: 2px;
            height: 5px;
            position: absolute;
            z-index: 2;
        }

        &-tip {
            left: 14px;
            top: 46px;
            transform: rotate(45deg);
            width: 25px;
            animation: animateSuccessTip .75s;
        }

        &-long {
            right: 8px;
            top: 38px;
            transform: rotate(-45deg);
            width: 47px;
            animation: animateSuccessLong .75s;
        }
    }
}

@keyframes animateSuccessTip {
    0%, 54% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }

    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }

    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes animateSuccessLong {
    0%, 65% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    84% {
        width: 55px;
        right: 0;
        top: 35px;
    }

    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

@keyframes rotatePlaceholder {
    0%, 5% {
        transform: rotate(-45deg);
    }

    100%, 12% {
        transform: rotate(-405deg);
    }
}

/* END SweetAlert success icon */

.grecaptcha-badge {
    visibility: hidden;
}

:focus {
    outline: none !important;
}

/*only for dev!*/
#components-reconnect-modal {
    display: none !important;
}

body {
    /*background-image: url(../../content/images/SPTEcoBG.svg);
    background-size: cover;
    background-color: #1c212d;*/
    overscroll-behavior-y: contain; /* contain importent, prevent pull to refresh in mobile chrome */
}

/*CUSTOM **/
#app-logo {
    height: 50px;
    /*width: $logoWith;*/
    /*background-image: $logoPath;*/
    background-repeat: no-repeat;
    background-size: contain;
}

nav.navbar.navbar-dark.nav-main {
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
}

.active .bs-stepper-circle {
    background-color: var(--highlight-color); //$highlight-color
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
    background-color: var(--customer); // $main-color
    color: #fff !important;
}

/*CUSTOM END **/



/*background responsive image*/
.bg-responsive-image {
    background-image: url(../../content/images/home-bg-image.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
    background-size: cover;
    background-color: #fff;
    height: 100%;
}

.bg-container {
    position: relative;
    background: #fff;
    /*background: #000;*/
    overflow: hidden;
    height: 100%;
}

.bg-container:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.9;
    background-image: url(../../content/images/home-bg-image.jpg);
    background-repeat: no-repeat;
    background-position: 50% 0;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
}

.bg-container .bg-content {
    z-index: 2;
    position: relative;
    color: #000;
}

.blazor-error-ui {
    background: lightyellow;
    bottom: 0;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.2);
    display: none;
    left: 0;
    padding: 0.6rem 1.25rem 0.7rem 1.25rem;
    position: fixed;
    width: 100%;
    z-index: 1000;
}

.blazor-error-ui .dismiss {
    cursor: pointer;
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
}

/*bs-stepper*/
.bs-stepper-content {
    padding: unset;
}

.dstepper-none {
    display: none !important;
}

.bs-stepper-circle {
    width: 1.5em;
    height: 1.5em;
    padding: unset;
    line-height: 1.5em;
    margin: unset;
}


.bs-stepper .step-trigger {
    padding: 5px; /*0 5px 0 5px*/
}

.md-FlyoutHeader {
    background-color: var(--customer); // $main-color
}

@media (max-width: 992px) {
    .bs-stepper {
        display: -ms-flexbox;
        display: flex;
    }

    .bs-stepper .bs-stepper-header {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: stretch;
        align-items: stretch;
        margin: 0;
    }

    .bs-stepper .step-trigger {
        padding: 5px; /*5px 0 5px 0*/
    }

    .bs-stepper .bs-stepper-line {
        /* todo how to center the line*/
        /*-ms-flex-preferred-size:;*/
        flex-basis: 20px;
    }
}


@media (min-width: 992px) {
    div#navbarCollapse {
        position: absolute;
    }
}


@media (max-width: 520px) {
    .bs-stepper .bs-stepper-header {
        text-align: unset;
    }

    .bs-stepper .step-trigger {
        -ms-flex-direction: unset;
        flex-direction: unset;
    }
}

@media (min-width: 992px) {
    .stepper-navbar .navbar-brand {
        display: none;
    }

    .stepper-navbar .progress {
        display: none;
    }
}

.unselectable {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
}

.sptInfoMessageSmall {
    /*position: absolute;
    width: 100%;
    top: calc(100% - 200px);
    left: 0;
    color: #FFF;
    font-family: clear_sans_regular;
    font-size: 1em;
    text-align: center;
    background-color: rgba(59, 65, 81, 0.9);
    padding: 0;
    */
    z-index: 120;
}

#InfoMessageSmallTextIcon {
    padding: 8px 4px 8px 0;
    vertical-align: middle;
}

.fadeIn_show {
    pointer-events: auto;
    -webkit-animation: fadeFromNone 0.3s ease-out 1 forwards;
    -moz-animation: fadeFromNone 0.3s ease-out 1 forwards;
    -o-animation: fadeFromNone 0.3s ease-out 1 forwards;
    animation: fadeFromNone 0.3s ease-out 1 forwards;
}

.fadeIn_hide {
    pointer-events: none;
    -webkit-animation: fadeToNone 0.3s ease-out 1 forwards;
    -moz-animation: fadeToNone 0.3s ease-out 1 forwards;
    -o-animation: fadeToNone 0.3s ease-out 1 forwards;
    animation: fadeToNone 0.3s ease-out 1 forwards;
}

/* electric Slider */

@media (max-width: 575px) {
    #yearlyConsumption .noUi-pips > .noUi-value {
        transform: rotate(90deg) translate(12px, 2px);
    }

    #yearlyConsumption .noUi-pips > .noUi-value ~ .noUi-value {
        transform: rotate(90deg) translate(30px, 20px);
    }
}

.noUi-connect {
    background-color: var(--customer) !important; // $main-color
}

.noUi-target, .no-Ui-target * {
    touch-action: unset !important;
}

.noUi-pips-horizontal {
    touch-action: unset !important;
}

.noUi-pips {
    touch-action: unset !important;
}

.noUi-value {
    touch-action: unset !important;
}


/* Shepherd Tour */

.shepherd-element {
    max-width: 280px !important;
}

.shepherd-text {
    font-size: smaller !important;
    overflow: auto !important;
}

.shepherd-title {
    font-size: small !important;
    color: #ffffff !important;
}

.shepherd-header {
    background: var(--customer) !important; // $main-color
    padding: 0.2em !important;
    padding-left: 0.6em !important;
}

.shepherd-button {
    padding: 0.2em 0.5em 0.2em 0.5em !important;
    background: var(--customer) !important; // $main-color
}

.shepherd-arrow ::before {
    background: var(--customer) !important; // $main-color
}

.shepherd-element.shepherd-has-title[data-popper-placement^=bottom] > .shepherd-arrow:before {
    background: var(--customer) !important; // $main-color
}

.shepherd-highlight {
    background: var(--customer) !important; // $main-color
    opacity: 0.5 !important;
    z-index: 9999 !important;
}

.shepherd-cancel-icon {
    color: #ffffff !important;
}

#reopenRoofTour:hover {
    background-color: #dadada !important;
}

.dontShowAgainTourBtn {
    font-size: small !important;
    background-color: var(--highlight-color) !important; //$highlight-color
    color: #ffffff !important;
}

.dontShowAgainTourBtn_notVisible {
    display: none;
}

/* ToolTip */
.tooltip {
    pointer-events: none !important;
}

.tooltip-inner {
    background-color: var(--customer) !important; // $main-color
}

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--customer) !important; // $main-color
}

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
    border-top-color: var(--customer) !important; // $main-color
}

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
    border-left-color: var(--customer) !important; // $main-color
}

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
    border-right-color: var(--customer) !important; // $main-color
}

/* roof helper dialog*/

.roofHelper_roofInfo {
    padding-left: 70px;
}

@media (max-width: 580px) {
    .roofHelper_modal-body {
        font-size: smaller !important;
        padding-top: 0.5rem;
        padding-bottom: 0;
    }

    .roofHelper_modal-title {
        font-size: smaller !important;
    }

    .roofHelper_roofInfo{
        padding-left: 15px;
    }

    .roofHelper_modal-footer{
        padding-top: 0;
        padding-bottom: 0;
    }

    .roofHelper_reopenBtn{
        font-size: .8rem;
    }
}

.tableInfo td {
    padding: 0 !important;
}

/* location info toast*/
@media (max-width: 520px) {
    .toast_location {
        font-size: small !important;
    }

    .toast-body_location{
        margin-bottom: unset !important;
        padding-bottom: unset !important;
        padding-top: unset !important;
    }
}

.pb-responsive2 {
    padding-bottom: 4rem !important;
}

.pb-responsive2small {
    padding-bottom:2rem !important;
}

@-webkit-keyframes fadeFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-moz-keyframes fadeFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-o-keyframes fadeFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes fadeFromNone {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@-webkit-keyframes fadeToNone {
    0% {
        display: block;
        opacity: 1;
    }

    99% {
        display: block;
        opacity: 0;
    }

    100% {
        display: none;
        opacity: 0;
    }
}

@-moz-keyframes fadeToNone {
    0% {
        display: block;
        opacity: 1;
    }

    99% {
        display: block;
        opacity: 0;
    }

    100% {
        display: none;
        opacity: 0;
    }
}

@-o-keyframes fadeToNone {
    0% {
        display: block;
        opacity: 1;
    }

    99% {
        display: block;
        opacity: 0;
    }

    100% {
        display: none;
        opacity: 0;
    }
}

@keyframes fadeToNone {
    0% {
        display: block;
        opacity: 1;
    }

    99% {
        display: block;
        opacity: 0;
    }

    100% {
        display: none;
        opacity: 0;
    }
}
