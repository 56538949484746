﻿.nav-scroller {
    max-width: 100%;
    overflow-y: hidden;
}

.nav-scroller-default {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    color: rgba(255, 255, 255, .75);
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    line-height: 1.5rem;
}

.nav-scroller .nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    color: rgba(255, 255, 255, .75);
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    line-height: 1.5rem;
}

.navbar-nav .nav-item .nav-link {
    padding-right: .8rem;
    padding-left: .8rem;
}

.nav-underline .nav-link {
    font-size: .875rem;
    color: #6c757d;
}

/*.nav-underline .nav-link:hover {
    color: #007bff;
}*/

.nav-underline .active {
    font-weight: 500;
    background-color: lightgray;
}

@mixin toolbar-orientation {
    .mapDrawerOrientation {
        flex-direction: row !important;
    }
    /*.dropdown-toolbar-custom {
        .dropdown-toggle::after {
            transform: rotate(45deg) !important;
        }
    }*/
    .ls-toolbar {
        width: auto;
        /*width: 100px;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;*/
        height: 100%;
        /*z-index: 999;*/
        overflow-y: auto;
    }

    .nav-scroller .nav {
        margin-top: auto 0;
        flex-direction: column !important;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        display: -ms-flexbox;
        /*-webkit-overflow-scrolling: touch;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;*/
    }
}


@include media-breakpoint-up(md) {
    @include toolbar-orientation;
}

@include media-breakpoint-down(sm) {
    @include orientation(landscape) {
        @include toolbar-orientation;
    }
}

/* tabs in toolbar-menu */
.tab-content-custom {
    height: 100%;
    padding-bottom: 42px; /*tabs header*/
}

.tab-content-custom .tab-pane {
    height: 100%;
    overflow: auto;
}

/*.toolbar-icon {
    width: 40px;
    height: 40px;
}*/

.ls-toolbar ul.nav li.nav-item .btn {
    font-size: 24px;
}