﻿$icons20X20: url('../../content/icons/icons_layout_20x20.png');
$icons30X30: url('../../content/icons/icons_layout_30x30.png');

#ao-Flyout {
    z-index: 1;
    /*overflow-x: auto;
    width: 400px;
    height: auto;
    background-color: rgba(246, 246, 246, 0.9);
    position: absolute;
    left: 42px;
    top: 0px;
    -webkit-box-shadow: 4px 5px 3px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 4px 5px 3px 0 rgba(0, 0, 0, 0.2);
    -ms-box-shadow: 4px 5px 3px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 4px 5px 3px 0 rgba(0, 0, 0, 0.2);*/
}

#staticTable div {
    font-size: 0.76em;
    bottom: 4px;
}

#insertWrapper {
    background: #b4b4b4;
    padding: 5px;
}

#insert-container {
    /*width: 105px;
        height: 33px;
        padding: 2px 0 5px 0;*/
    display: block;
}

.ao-insert-item-group {
    width: 30px;
    height: 30px;
    display: block;
    float: left;
    margin: 5px;
    background-color: transparent !important;
    border-radius: 4px;
    cursor: pointer;
}

.ao-insert-item-group:hover {
    margin: 4px;
    border: 1px solid #b4b4b4;
    -ms-opacity: 0.75;
    opacity: 0.75;
}

.ao-insert-item-group-selected {
    margin: 4px;
    border: 1px solid #b4b4b4;
}

.ao-insert-item-group-addon {
    display: block;
    /*padding: 0 10px 5px 1px;*/
    border: 1px solid #b4b4b4;
    border-radius: 4px;
    border-left: none;
    position: absolute;
    margin-left: 35px;
    margin-top: -6px;
    background-color: #F6F6F6;
}

.ao-insert-item {
    width: 30px;
    height: 30px;
    /*display: block;
    float: left;
    margin: 5px;
    background-color: transparent !important;
    border-radius: 4px;*/
    cursor: pointer;
}

.ao-insert-item:hover {
    /*margin: 4px;*/
    /*border: 1px solid #b4b4b4;*/
    -ms-opacity: 0.75;
    opacity: 0.75;
    /*background-color: #FFFFFF !important;
        margin: 1px 0 0 1px;
        border: 2px solid #FFFFFF;*/
}

.ao-insert-item-selected {
    background-color: #dadada;
    /*margin: 4px;*/
    /*border: 1px solid #b4b4b4;*/
    /*background-color: #c0c0c0 !important;*/
    /*margin: 1px 0 0 1px;*/
    /*border: 2px solid #c0c0c0;*/
}

.tools-addon {
    display: block;
    /*padding: 0 10px 5px 1px;*/
    border: 1px solid #b4b4b4;
    border-radius: 4px;
    border-left: none;
    position: absolute;
    margin-left: 35px;
    margin-top: -1px;
    height: 30px;
    background-color: #F6F6F6;
    /*z-index: 1;*/
    /*clear: both;*/
}

.ao-insert-tool {
    width: 22px;
    height: 22px;
    display: block;
    float: right;
    margin: 4px;
    margin-right: 6px;
    background-color: transparent;
    border: 1px solid #F6F6F6;
}

.ao-tb-spacer {
    height: 11px;
    width: 100%;
    margin: 0px;
    background-image: url('../../content/images/TB_Spacer.png');
    background-repeat: no-repeat;
    background-position-y: 5px;
}

.ao-insert-tool:hover {
    border: 1px solid #dadada;
    border-radius: 2px;
}

.ao-tb-spacer:hover {
    border: 1px solid transparent;
}

.ao-insert-tool-selected {
    border: 1px solid #b4b4b4;
    border-radius: 2px;
}

#cad-preview-wrapper {
    background: transparent;
    padding: 5px;
}

#cad-preview-wrapper > .ContainerElement {
    margin-top: 5px;
}

#cad-preview {
    width: 100%;
    /*height: 200px;*/
    background: transparent;
    text-align: center;
    padding-top: 2px;
}

#aoes5-view-container {
    /*padding: 5px 5px 5px 5px;*/
    background-color: #ffffff !important;
    /*margin-right: 15px;*/
}

#aoes5-tb {
    padding-bottom: 12px;
}

#insert-label {
    border-bottom: 1px solid #a1a1a1;
}

#ao-tools-overview, #ao-tools-edit {
    padding: 0;
    margin-top: 0;
    /*overflow-y: auto;*/
}

.raLabel {
    text-align: right;
    width: 100px;
}

.aoes5-info-tr-spacer > td {
    height: 15px;
}

#ao-Toolbox {
    /*position: absolute;
    left: 0px;*/
    /*top: 155px;*/
    /*width: 40px;
    min-height: 300px;*/
    background-color: #F6F6F6;
    /*z-index: 2;*/
}

#nav-dropdown {
    overflow-x: auto;
    width: auto;
    height: auto;
    background-color: rgba(246, 246, 246, 0.9);
    position: absolute;
}

#ao-Flyout-CloseButton {
    cursor: pointer;
}

#ao-Flyout .ContainerElement {
    background-color: transparent;
}

#ao3dHelpDialog {
    height: auto !important;
}

#ao-Statusbar {
    /*width: 100%;
    height: 40px;*/
    background-color: #F6F6F6;
    /*padding-top: 8px;
    white-space: nowrap;*/
    overflow: hidden;
}

.ao-Statusbar-Label {
    padding-left: 10px;
    padding-right: 5px;
    color: #878787;
}

.ao-Statusbar-Value {
    background-color: transparent;
    border: none;
    width: 50px;
    color: #878787;
}

.ao-Statusbar-Spacer {
    width: 25px;
}

.ao-cadPreview {
    background-color: transparent !important;
}

#aoShadowLegend {
    position: absolute;
    width: 40px;
    height: 575px;
    left: 50px;
    margin-top: 5px;
    background: transparent url('../../Content/images/ShadowLegend.png') no-repeat;
    -ms-opacity: 0.9;
    opacity: 0.9;
    filter: alpha(opacity=90); /* For IE8 and earlier */
    z-index: 1;
}

.ao-ShadowLegendIndicator {
    color: #878787;
    font-size: 0.75em;
}

div.colorPicker-picker {
    height: 16px;
    width: 16px;
    padding: 0 !important;
    border: 1px solid #ccc;
    background: url(arrow.gif) no-repeat top right;
    cursor: pointer;
    line-height: 16px;
    font-size: 0.75em;
    font-weight: bold;
    text-align: center;
}

div.colorPicker-palette {
    width: 110px;
    position: absolute;
    border: 1px solid #598FEF;
    background-color: #EFEFEF;
    padding: 2px;
    z-index: 9999;
}

div.colorPicker_hexWrap {
    width: 100%;
    float: left
}

div.colorPicker_hexWrap label {
    font-size: 95%;
    color: #2F2F2F;
    margin: 5px 2px;
    width: 25%
}

div.colorPicker_hexWrap input {
    margin: 5px 2px;
    padding: 0;
    font-size: 95%;
    border: 1px solid #000;
    width: 65%;
}

div.colorPicker-swatch {
    height: 12px;
    width: 12px;
    border: 1px solid #000;
    margin: 2px;
    float: left;
    cursor: pointer;
    line-height: 12px;
}

.ao-section-headline {
    color: #1582c5;
    line-height: 40px;
    padding-left: 30px;
    font-size: 1.2em;
}

.ao-section-name, .ao-section-value {
    line-height: 30px;
    padding-left: 2px;
}

.ao-inter td {
    padding: 2px 2px 2px 0;
    margin: 0;
}

.ao-inter-selected td {
    border-bottom: 1px solid #007fc4;
    border-top: 1px solid #007fc4;
}

.ao-inter-selected td:first-child {
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 1px solid #007fc4;
}

.ao-inter-selected td:last-child {
    -webkit-border-top-right-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    -moz-border-radius-topright: 3px;
    -moz-border-radius-bottomright: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: 1px solid #007fc4;
}

.ao-inter-title > input {
    width: 90px;
}

.ao-inter-height > input {
    width: 50px;
}

.ao-inter-unit > span, .ao-slope-unit > span {
    margin: 0 3px;
}

.ao-inter-delete > div, .ao-inter-p-delete > div {
    background: $icons20X20 no-repeat -40px -40px;
    -ms-background-clip: padding-box;
    background-clip: padding-box;
    width: 20px;
    height: 20px;
    float: right;
    cursor: pointer;
    padding: 1px;
    border: 1px solid #384547;
    -ms-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}

.ao-inter-lock {
    background: $icons20X20 no-repeat -120px -40px;
    cursor: pointer;
    margin: 1px;
    width: 20px;
    height: 20px;
    -ms-opacity: 0.6;
    opacity: 0.6;
}

.ao-inter-lock:hover {
    -ms-opacity: 0.4;
    opacity: 0.4;
}

.ao-inter-unlock {
    background: $icons20X20 no-repeat -100px -40px;
    cursor: pointer;
    margin: 1px;
    width: 20px;
    height: 20px;
}

.ao-inter-unlock:hover {
    -ms-opacity: 0.6;
    opacity: 0.6;
}

.ao-inter-delete > div, .ao-inter-p-delete > div {
    background: $icons20X20 no-repeat -40px -40px;
    -ms-background-clip: padding-box;
    background-clip: padding-box;
    width: 20px;
    height: 20px;
    float: right;
    cursor: pointer;
    padding: 1px;
    border: 1px solid #384547;
    -ms-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}

.ao-inter-delete-locked, .ao-inter-unit-locked {
    -ms-opacity: 0.6;
    opacity: 0.6;
}

.ao-inter-delete-locked > div {
    cursor: default;
}

.ao-inter-p-delete > div {
    margin-left: 2px;
}

.ao-inter-delete > div:hover, .ao-inter-p-delete > div:hover {
    -ms-opacity: 0.6;
    opacity: 0.6;
}

.ao-inter-delete-locked > div:hover {
    -ms-opacity: 1;
    opacity: 1;
}

.ao-inter-p > td > input {
    width: 50px;
}

.ao-inter-p .tdLabel {
    width: 40px;
}

.inline-ao-ribbonItem {
    margin: 10px 0 0 10px;
    float: left;
}

.inline-ao-ribbonItem > .RibbonItem {
    float: none;
    padding: 0;
    margin: 0;
}

.inline-ao-ribbonItem .RibbonItem .RibbonItemText .ribbon_label {
    color: #384547;
}

.ao-section-hori-line {
    width: 100%;
    border-bottom: 1px #007fc4 solid;
    height: 5px;
    margin-bottom: 5px;
}

.ao-section-headline {
    color: #1582c5;
    line-height: 40px;
    padding-left: 30px;
    font-size: 1.2em;
}

#ao-view-layers {
    position: absolute;
    margin-top: -58px;
    margin-left: 10px;
    display: inline-flex;
    z-index: 2;
}

.ao-view-layer {
    padding: 3px;
    background: #F6F6F6;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-right: 10px;
}

.ao-view-layer > div {
    background: white;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin: 2px;
    border: none;
    cursor: pointer;
}

.ao-view-layer > div:hover {
    margin: 0px;
    border: 2px solid #777777;
    -ms-opacity: 0.6;
    opacity: 0.6;
}

.ao-view-layer-selected > div {
    margin: 0px;
    border: 2px solid #555555;
}

.ao-label-icon {
    background: $icons20X20 no-repeat -60px -100px;
    cursor: pointer;
    margin: 1px;
    width: 20px;
    height: 20px;
}

.ao-label-icon:hover {
    -ms-opacity: 0.8;
    opacity: 0.8;
}

.ao-label-icon-inactive {
    background: $icons20X20 no-repeat -60px -100px;
    margin: 1px;
    width: 20px;
    height: 20px;
    -ms-opacity: 0.4;
    opacity: 0.4;
}
