﻿.sidebar-fixed {
    /*display: flex;
    width: 100%;*/
    width: 250px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 999;
    background: #7386D5;
    color: #fff;
    /*transition: all 0.3s;*/
}
