$icomoon-font-family: "SPTX-TOOLBAR";
$icomoon-font-path: "../../../../styles/webfonts/custom/ToolbarIcon";

$ToolbarIcon-E-DesignerEditGraph: "\eb59";
$ToolbarIcon-E-DesignerVerbraucher: "\eb3a";
$ToolbarIcon-E-DesignerWechselrichter: "\eb3b";
$ToolbarIcon-E-DesignerBatterie: "\eb3c";
$ToolbarIcon-E-DesignerSimulieren-path1: "\eb3d";
$ToolbarIcon-E-DesignerSimulieren-path2: "\eb3e";
$ToolbarIcon-E-DesignerSimulieren-path3: "\eb3f";
$ToolbarIcon-E-DesignerSimulieren-path4: "\eb40";
$ToolbarIcon-E-DesignerErtrag: "\eb41";
$ToolbarIcon-E-DesignerHorizont: "\eb42";
$ToolbarIcon-E-DesignerDCAC-path1: "\eb43";
$ToolbarIcon-E-DesignerDCAC-path2: "\eb44";
$ToolbarIcon-E-DesignerACDC-path1: "\eb45";
$ToolbarIcon-E-DesignerACDC-path2: "\eb46";
$ToolbarIcon-E-DesignerGF-path1: "\eb47";
$ToolbarIcon-E-DesignerGF-path2: "\eb48";
$ToolbarIcon-E-DesignerLupe: "\eb49";
$ToolbarIcon-E-DesignerEnergieFluss: "\eb4a";
$ToolbarIcon-E-Designer-I: "\eb4b";
$ToolbarIcon-E-DesignerPen: "\eb4c";
$ToolbarIcon-E-DesignerDelete: "\eb4d";
$ToolbarIcon-E-DesignerAdd: "\eb4e";
$ToolbarIcon-E-DesignerCopy: "\eb4f";
$ToolbarIcon-E-DesignerAddRoof-path1: "\eb50";
$ToolbarIcon-E-DesignerAddRoof-path2: "\eb51";
$ToolbarIcon-E-DesignerAddRoof-path3: "\eb52";
$ToolbarIcon-E-DesignerHome: "\eb53";
$ToolbarIcon-E-DesignerInfo: "\eb54";
$ToolbarIcon-E-DesignerEnergieBilanz: "\eb55";
$ToolbarIcon-E-DesignerAuslegungsVarianten: "\eb56";
$ToolbarIcon-E-DesignerRevert: "\eb57";
$ToolbarIcon-E-DesignerZahnrad: "\eb58";
$ToolbarIcon-VGuide-path1: "\e900";
$ToolbarIcon-VGuide-path2: "\e901";
$ToolbarIcon-VGuide-path3: "\e902";
$ToolbarIcon-FensterH-path1: "\e903";
$ToolbarIcon-FensterH-path2: "\e904";
$ToolbarIcon-FensterH-path3: "\e905";
$ToolbarIcon-AddFeld-path1: "\e906";
$ToolbarIcon-AddFeld-path2: "\e907";
$ToolbarIcon-Was01-path1: "\e908";
$ToolbarIcon-Was01-path2: "\e909";
$ToolbarIcon-Was01-path3: "\e90a";
$ToolbarIcon-Was01-path4: "\e90b";
$ToolbarIcon-Was02-path1: "\e90c";
$ToolbarIcon-Was02-path2: "\e90d";
$ToolbarIcon-Was02-path3: "\e90e";
$ToolbarIcon-DelPoint-path1: "\e90f";
$ToolbarIcon-DelPoint-path2: "\e910";
$ToolbarIcon-AddPoint-path1: "\e911";
$ToolbarIcon-AddPoint-path2: "\e912";
$ToolbarIcon-SetAttika-path1: "\e913";
$ToolbarIcon-SetAttika-path2: "\e914";
$ToolbarIcon-MultiFirst-path1: "\e915";
$ToolbarIcon-MultiFirst-path2: "\e916";
$ToolbarIcon-MultiTraufe-path1: "\e917";
$ToolbarIcon-MultiTraufe-path2: "\e918";
$ToolbarIcon-DetachFlyout-path1: "\e919";
$ToolbarIcon-DetachFlyout-path2: "\e91a";
$ToolbarIcon-DetachFlyout-path3: "\e91b";
$ToolbarIcon-MoreSpaceV-path1: "\e91c";
$ToolbarIcon-MoreSpaceV-path2: "\e91d";
$ToolbarIcon-MoreSpaceV-path3: "\e91e";
$ToolbarIcon-MoreSpaceV-path4: "\e91f";
$ToolbarIcon-MoreSpaceH-path1: "\e920";
$ToolbarIcon-MoreSpaceH-path2: "\e921";
$ToolbarIcon-MoreSpaceH-path3: "\e922";
$ToolbarIcon-MoreSpaceH-path4: "\e923";
$ToolbarIcon-InverterBankH-path1: "\e924";
$ToolbarIcon-InverterBankH-path2: "\e925";
$ToolbarIcon-InverterBankH-path3: "\e926";
$ToolbarIcon-InverterBankH-path4: "\e927";
$ToolbarIcon-InverterBankH-path5: "\e928";
$ToolbarIcon-InverterBankH-path6: "\e929";
$ToolbarIcon-InverterBankH-path7: "\e92a";
$ToolbarIcon-SUNSTYLEZiegelZuschneidbar-path1: "\e92b";
$ToolbarIcon-SUNSTYLEZiegelZuschneidbar-path2: "\e92c";
$ToolbarIcon-SUNSTYLEZiegelZuschneidbar-path3: "\e92d";
$ToolbarIcon-Refresh: "\e92e";
$ToolbarIcon-Save: "\e92f";
$ToolbarIcon-Revert: "\e930";
$ToolbarIcon-Befestiger-path1: "\e931";
$ToolbarIcon-Befestiger-path2: "\e932";
$ToolbarIcon-SUNSTYLESolarziegel-path1: "\e933";
$ToolbarIcon-SUNSTYLESolarziegel-path2: "\e934";
$ToolbarIcon-SUNSTYLESolarziegel-path3: "\e935";
$ToolbarIcon-SUNSTYLESolarziegel-path4: "\e936";
$ToolbarIcon-SUNSTYLESolarziegel-path5: "\e937";
$ToolbarIcon-SUNSTYLESolarziegel-path6: "\e938";
$ToolbarIcon-SUNSTYLESolarziegel-path7: "\e939";
$ToolbarIcon-SUNSTYLESolarziegel-path8: "\e93a";
$ToolbarIcon-SUNSTYLESolarziegel-path9: "\e93b";
$ToolbarIcon-SUNSTYLESolarziegel-path10: "\e93c";
$ToolbarIcon-SUNSTYLESolarziegel-path11: "\e93d";
$ToolbarIcon-SUNSTYLESolarziegel-path12: "\e93e";
$ToolbarIcon-SUNSTYLESolarziegel-path13: "\e93f";
$ToolbarIcon-SUNSTYLESolarziegel-path14: "\e940";
$ToolbarIcon-SUNSTYLESolarziegel-path15: "\e941";
$ToolbarIcon-SUNSTYLESolarziegel-path16: "\e942";
$ToolbarIcon-SUNSTYLESolarziegel-path17: "\e943";
$ToolbarIcon-SUNSTYLESolarziegel-path18: "\e944";
$ToolbarIcon-SUNSTYLESolarziegel-path19: "\e945";
$ToolbarIcon-SUNSTYLESolarziegel-path20: "\e946";
$ToolbarIcon-SUNSTYLESolarziegel-path21: "\e947";
$ToolbarIcon-SUNSTYLESolarziegel-path22: "\e948";
$ToolbarIcon-SUNSTYLESolarziegel-path23: "\e949";
$ToolbarIcon-SUNSTYLESolarziegel-path24: "\e94a";
$ToolbarIcon-SUNSTYLESolarziegel-path25: "\e94b";
$ToolbarIcon-SUNSTYLESolarziegel-path26: "\e94c";
$ToolbarIcon-SUNSTYLEZiegel: "\e94d";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path1: "\e94e";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path2: "\e94f";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path3: "\e950";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path4: "\e951";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path5: "\e952";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path6: "\e953";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path7: "\e954";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path8: "\e955";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path9: "\e956";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path10: "\e957";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path11: "\e958";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path12: "\e959";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path13: "\e95a";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path14: "\e95b";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path15: "\e95c";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path16: "\e95d";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path17: "\e95e";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path18: "\e95f";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path19: "\e960";
$ToolbarIcon-SUNSTYLESolarziegelUnten-path20: "\e961";
$ToolbarIcon-SUNSTYLESolarziegelOben-path1: "\e962";
$ToolbarIcon-SUNSTYLESolarziegelOben-path2: "\e963";
$ToolbarIcon-SUNSTYLESolarziegelOben-path3: "\e964";
$ToolbarIcon-SUNSTYLESolarziegelOben-path4: "\e965";
$ToolbarIcon-SUNSTYLESolarziegelOben-path5: "\e966";
$ToolbarIcon-SUNSTYLESolarziegelOben-path6: "\e967";
$ToolbarIcon-SUNSTYLESolarziegelOben-path7: "\e968";
$ToolbarIcon-SUNSTYLESolarziegelOben-path8: "\e969";
$ToolbarIcon-SUNSTYLESolarziegelOben-path9: "\e96a";
$ToolbarIcon-SUNSTYLESolarziegelOben-path10: "\e96b";
$ToolbarIcon-SUNSTYLESolarziegelOben-path11: "\e96c";
$ToolbarIcon-SUNSTYLESolarziegelOben-path12: "\e96d";
$ToolbarIcon-SUNSTYLESolarziegelOben-path13: "\e96e";
$ToolbarIcon-SUNSTYLESolarziegelOben-path14: "\e96f";
$ToolbarIcon-SUNSTYLESolarziegelOben-path15: "\e970";
$ToolbarIcon-SUNSTYLESolarziegelOben-path16: "\e971";
$ToolbarIcon-SUNSTYLESolarziegelOben-path17: "\e972";
$ToolbarIcon-SUNSTYLESolarziegelOben-path18: "\e973";
$ToolbarIcon-SUNSTYLESolarziegelOben-path19: "\e974";
$ToolbarIcon-SUNSTYLESolarziegelOben-path20: "\e975";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path1: "\e976";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path2: "\e977";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path3: "\e978";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path4: "\e979";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path5: "\e97a";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path6: "\e97b";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path7: "\e97c";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path8: "\e97d";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path9: "\e97e";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path10: "\e97f";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path11: "\e980";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path12: "\e981";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path13: "\e982";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path14: "\e983";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path15: "\e984";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path16: "\e985";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path17: "\e986";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path18: "\e987";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path19: "\e988";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path20: "\e989";
$ToolbarIcon-SUNSTYLESolarziegelRechts-path21: "\e98a";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path1: "\e98b";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path2: "\e98c";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path3: "\e98d";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path4: "\e98e";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path5: "\e98f";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path6: "\e990";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path7: "\e991";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path8: "\e992";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path9: "\e993";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path10: "\e994";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path11: "\e995";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path12: "\e996";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path13: "\e997";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path14: "\e998";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path15: "\e999";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path16: "\e99a";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path17: "\e99b";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path18: "\e99c";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path19: "\e99d";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path20: "\e99e";
$ToolbarIcon-SUNSTYLESolarziegelLinks-path21: "\e99f";
$ToolbarIcon-SUNSTYLEZiegelUnten-path1: "\e9a0";
$ToolbarIcon-SUNSTYLEZiegelUnten-path2: "\e9a1";
$ToolbarIcon-SUNSTYLEZiegelOben-path1: "\e9a2";
$ToolbarIcon-SUNSTYLEZiegelOben-path2: "\e9a3";
$ToolbarIcon-SUNSTYLEZiegelLinks-path1: "\e9a4";
$ToolbarIcon-SUNSTYLEZiegelLinks-path2: "\e9a5";
$ToolbarIcon-SUNSTYLEZiegelRechts-path1: "\e9a6";
$ToolbarIcon-SUNSTYLEZiegelRechts-path2: "\e9a7";
$ToolbarIcon-SUNSTYLEEckeGrossUntenLinks-path1: "\e9a8";
$ToolbarIcon-SUNSTYLEEckeGrossUntenLinks-path2: "\e9a9";
$ToolbarIcon-SUNSTYLEEckeGrossUntenRechts-path1: "\e9aa";
$ToolbarIcon-SUNSTYLEEckeGrossUntenRechts-path2: "\e9ab";
$ToolbarIcon-SUNSTYLEEckeGrossObenLinks-path1: "\e9ac";
$ToolbarIcon-SUNSTYLEEckeGrossObenLinks-path2: "\e9ad";
$ToolbarIcon-SUNSTYLEEckeGrossObenRechts-path1: "\e9ae";
$ToolbarIcon-SUNSTYLEEckeGrossObenRechts-path2: "\e9af";
$ToolbarIcon-SUNSTYLEEckeKleinUntenLinks-path1: "\e9b0";
$ToolbarIcon-SUNSTYLEEckeKleinUntenLinks-path2: "\e9b1";
$ToolbarIcon-SUNSTYLEEckeKlainUntenRechts-path1: "\e9b2";
$ToolbarIcon-SUNSTYLEEckeKlainUntenRechts-path2: "\e9b3";
$ToolbarIcon-SUNSTYLEEckeKleinObenRechts-path1: "\e9b4";
$ToolbarIcon-SUNSTYLEEckeKleinObenRechts-path2: "\e9b5";
$ToolbarIcon-SUNSTYLEEckeKleinObenLinks-path1: "\e9b6";
$ToolbarIcon-SUNSTYLEEckeKleinObenLinks-path2: "\e9b7";
$ToolbarIcon-SUNSTYLEZiegelUntenKlein-path1: "\e9b8";
$ToolbarIcon-SUNSTYLEZiegelUntenKlein-path2: "\e9b9";
$ToolbarIcon-SUNSTYLEZiegelObenKlein-path1: "\e9ba";
$ToolbarIcon-SUNSTYLEZiegelObenKlein-path2: "\e9bb";
$ToolbarIcon-InverterBankV-path1: "\e9bc";
$ToolbarIcon-InverterBankV-path2: "\e9bd";
$ToolbarIcon-InverterBankV-path3: "\e9be";
$ToolbarIcon-InverterBankV-path4: "\e9bf";
$ToolbarIcon-InverterBankV-path5: "\e9c0";
$ToolbarIcon-InverterBankV-path6: "\e9c1";
$ToolbarIcon-InverterBankV-path7: "\e9c2";
$ToolbarIcon-Pen: "\e9c3";
$ToolbarIcon-ACricle-path1: "\e9c4";
$ToolbarIcon-ACricle-path2: "\e9c5";
$ToolbarIcon-Tools: "\e9c6";
$ToolbarIcon-Cut-path1: "\e9c7";
$ToolbarIcon-Cut-path2: "\e9c8";
$ToolbarIcon-Cut-path3: "\e9c9";
$ToolbarIcon-Cut-path4: "\e9ca";
$ToolbarIcon-Cut-path5: "\e9cb";
$ToolbarIcon-Cut-path6: "\e9cc";
$ToolbarIcon-Cut-path7: "\e9cd";
$ToolbarIcon-Cut-path8: "\e9ce";
$ToolbarIcon-KabelErsetzen-path1: "\e9cf";
$ToolbarIcon-KabelErsetzen-path2: "\e9d0";
$ToolbarIcon-KabelErsetzen-path3: "\e9d1";
$ToolbarIcon-KabelErsetzen-path4: "\e9d2";
$ToolbarIcon-PolylinienAnzeigen-path1: "\e9d3";
$ToolbarIcon-PolylinienAnzeigen-path2: "\e9d4";
$ToolbarIcon-PolylinienAnzeigen-path3: "\e9d5";
$ToolbarIcon-KabelGenerieren: "\e9d6";
$ToolbarIcon-ProfilTool-path1: "\e9d7";
$ToolbarIcon-ProfilTool-path2: "\e9d8";
$ToolbarIcon-ErdungsTool: "\e9d9";
$ToolbarIcon-VerstringungTool-path1: "\e9da";
$ToolbarIcon-VerstringungTool-path2: "\e9db";
$ToolbarIcon-VerstringungTool-path3: "\e9dc";
$ToolbarIcon-InterferenceObj-path1: "\e9dd";
$ToolbarIcon-InterferenceObj-path2: "\e9de";
$ToolbarIcon-Gaube-path1: "\e9df";
$ToolbarIcon-Gaube-path2: "\e9e0";
$ToolbarIcon-Gaube-path3: "\e9e1";
$ToolbarIcon-Gaube-path4: "\e9e2";
$ToolbarIcon-Gaube-path5: "\e9e3";
$ToolbarIcon-Gaube-path6: "\e9e4";
$ToolbarIcon-KabelKanal-path1: "\e9e5";
$ToolbarIcon-KabelKanal-path2: "\e9e6";
$ToolbarIcon-NoDocking-path1: "\e9e7";
$ToolbarIcon-NoDocking-path2: "\e9e8";
$ToolbarIcon-Docking: "\e9e9";
$ToolbarIcon-StringTool-path1: "\e9ea";
$ToolbarIcon-StringTool-path2: "\e9eb";
$ToolbarIcon-StringTool-path3: "\e9ec";
$ToolbarIcon-StringTool-path4: "\e9ed";
$ToolbarIcon-RamSouth-path1: "\e9ee";
$ToolbarIcon-RamSouth-path2: "\e9ef";
$ToolbarIcon-RamWest-path1: "\e9f0";
$ToolbarIcon-RamWest-path2: "\e9f1";
$ToolbarIcon-RamNorth-path1: "\e9f2";
$ToolbarIcon-RamNorth-path2: "\e9f3";
$ToolbarIcon-RamEast-path1: "\e9f4";
$ToolbarIcon-RamEast-path2: "\e9f5";
$ToolbarIcon-ShowHideStringList-path1: "\e9f6";
$ToolbarIcon-ShowHideStringList-path2: "\e9f7";
$ToolbarIcon-ShowHideStringList-path3: "\e9f8";
$ToolbarIcon-ShowHideStringList-path4: "\e9f9";
$ToolbarIcon-ShowHideStringList-path5: "\e9fa";
$ToolbarIcon-DachSeiteMessen-path1: "\e9fb";
$ToolbarIcon-DachSeiteMessen-path2: "\e9fc";
$ToolbarIcon-DachSeiteMessen-path3: "\e9fd";
$ToolbarIcon-DachSeiteMessen-path4: "\e9fe";
$ToolbarIcon-DachSeiteMessen-path5: "\e9ff";
$ToolbarIcon-DachSeiteMessen-path6: "\ea00";
$ToolbarIcon-DachSeiteMessen-path7: "\ea01";
$ToolbarIcon-DachSeiteMessen-path8: "\ea02";
$ToolbarIcon-DachSeiteMessen-path9: "\ea03";
$ToolbarIcon-SelectArea-path1: "\ea04";
$ToolbarIcon-SelectArea-path2: "\ea05";
$ToolbarIcon-DrawSingleCurve-path1: "\ea06";
$ToolbarIcon-DrawSingleCurve-path2: "\ea07";
$ToolbarIcon-DrawSinglePoly-path1: "\ea08";
$ToolbarIcon-DrawSinglePoly-path2: "\ea09";
$ToolbarIcon-Draw3Eck-path1: "\ea0a";
$ToolbarIcon-Draw3Eck-path2: "\ea0b";
$ToolbarIcon-DrawRect-path1: "\ea0c";
$ToolbarIcon-DrawRect-path2: "\ea0d";
$ToolbarIcon-DrawCircle-path1: "\ea0e";
$ToolbarIcon-DrawCircle-path2: "\ea0f";
$ToolbarIcon-DrawPloy-path1: "\ea10";
$ToolbarIcon-DrawPloy-path2: "\ea11";
$ToolbarIcon-DelVerbindung-path1: "\ea12";
$ToolbarIcon-DelVerbindung-path2: "\ea13";
$ToolbarIcon-Verbindung: "\ea14";
$ToolbarIcon-Durchdringung-path1: "\ea15";
$ToolbarIcon-Durchdringung-path2: "\ea16";
$ToolbarIcon-DelFeld-path1: "\ea17";
$ToolbarIcon-DelFeld-path2: "\ea18";
$ToolbarIcon-DelFeld-path3: "\ea19";
$ToolbarIcon-ModH6Ext-path1: "\ea1a";
$ToolbarIcon-ModH6Ext-path2: "\ea1b";
$ToolbarIcon-ModH6Ext-path3: "\ea1c";
$ToolbarIcon-ModH6Ext-path4: "\ea1d";
$ToolbarIcon-ModH6Ext-path5: "\ea1e";
$ToolbarIcon-ModH6Ext-path6: "\ea1f";
$ToolbarIcon-ModH6Ext-path7: "\ea20";
$ToolbarIcon-ModH6Ext-path8: "\ea21";
$ToolbarIcon-ModH6Ext-path9: "\ea22";
$ToolbarIcon-ModH6Ext-path10: "\ea23";
$ToolbarIcon-ModH6Ext-path11: "\ea24";
$ToolbarIcon-ModH6Ext-path12: "\ea25";
$ToolbarIcon-ModH6Ext-path13: "\ea26";
$ToolbarIcon-ModH6Ext-path14: "\ea27";
$ToolbarIcon-ModH6Ext-path15: "\ea28";
$ToolbarIcon-ModH6Ext-path16: "\ea29";
$ToolbarIcon-ModH6Ext-path17: "\ea2a";
$ToolbarIcon-ModH6Ext-path18: "\ea2b";
$ToolbarIcon-ModH6Ext-path19: "\ea2c";
$ToolbarIcon-ModH6Ext-path20: "\ea2d";
$ToolbarIcon-ModH5-path1: "\ea2e";
$ToolbarIcon-ModH5-path2: "\ea2f";
$ToolbarIcon-ModH5-path3: "\ea30";
$ToolbarIcon-ModH6-path1: "\ea31";
$ToolbarIcon-ModH6-path2: "\ea32";
$ToolbarIcon-ModH6-path3: "\ea33";
$ToolbarIcon-ModH6-path4: "\ea34";
$ToolbarIcon-ModH6-path5: "\ea35";
$ToolbarIcon-ModH6-path6: "\ea36";
$ToolbarIcon-ModH6-path7: "\ea37";
$ToolbarIcon-ModH6-path8: "\ea38";
$ToolbarIcon-ModH6-path9: "\ea39";
$ToolbarIcon-ModH6-path10: "\ea3a";
$ToolbarIcon-ModH6-path11: "\ea3b";
$ToolbarIcon-ModH6-path12: "\ea3c";
$ToolbarIcon-ModH6-path13: "\ea3d";
$ToolbarIcon-ModH6-path14: "\ea3e";
$ToolbarIcon-ModH6-path15: "\ea3f";
$ToolbarIcon-ModH6-path16: "\ea40";
$ToolbarIcon-ModH6-path17: "\ea41";
$ToolbarIcon-ModH6-path18: "\ea42";
$ToolbarIcon-ModH6-path19: "\ea43";
$ToolbarIcon-ModH6-path20: "\ea44";
$ToolbarIcon-ModH4Ext-path1: "\ea45";
$ToolbarIcon-ModH4Ext-path2: "\ea46";
$ToolbarIcon-ModH4Ext-path3: "\ea47";
$ToolbarIcon-ModH4Ext-path4: "\ea48";
$ToolbarIcon-ModH4Ext-path5: "\ea49";
$ToolbarIcon-ModH4Ext-path6: "\ea4a";
$ToolbarIcon-ModH4Ext-path7: "\ea4b";
$ToolbarIcon-ModH4Ext-path8: "\ea4c";
$ToolbarIcon-ModH4Ext-path9: "\ea4d";
$ToolbarIcon-ModH4Ext-path10: "\ea4e";
$ToolbarIcon-ModH4Ext-path11: "\ea4f";
$ToolbarIcon-ModH4Ext-path12: "\ea50";
$ToolbarIcon-ModH4Ext-path13: "\ea51";
$ToolbarIcon-ModH4Ext-path14: "\ea52";
$ToolbarIcon-ModH4-path1: "\ea53";
$ToolbarIcon-ModH4-path2: "\ea54";
$ToolbarIcon-ModH4-path3: "\ea55";
$ToolbarIcon-ModH4-path4: "\ea56";
$ToolbarIcon-ModH4-path5: "\ea57";
$ToolbarIcon-ModH4-path6: "\ea58";
$ToolbarIcon-ModH4-path7: "\ea59";
$ToolbarIcon-ModH4-path8: "\ea5a";
$ToolbarIcon-ModH4-path9: "\ea5b";
$ToolbarIcon-ModH4-path10: "\ea5c";
$ToolbarIcon-ModH4-path11: "\ea5d";
$ToolbarIcon-ModH4-path12: "\ea5e";
$ToolbarIcon-ModH4-path13: "\ea5f";
$ToolbarIcon-ModH4-path14: "\ea60";
$ToolbarIcon-ModH3Ext-path1: "\ea61";
$ToolbarIcon-ModH3Ext-path2: "\ea62";
$ToolbarIcon-ModH3Ext-path3: "\ea63";
$ToolbarIcon-ModH3Ext-path4: "\ea64";
$ToolbarIcon-ModH3Ext-path5: "\ea65";
$ToolbarIcon-ModH3Ext-path6: "\ea66";
$ToolbarIcon-ModH3Ext-path7: "\ea67";
$ToolbarIcon-ModH3Ext-path8: "\ea68";
$ToolbarIcon-ModH3Ext-path9: "\ea69";
$ToolbarIcon-ModH3Ext-path10: "\ea6a";
$ToolbarIcon-ModH3Ext-path11: "\ea6b";
$ToolbarIcon-ModH3-path1: "\ea6c";
$ToolbarIcon-ModH3-path2: "\ea6d";
$ToolbarIcon-ModH3-path3: "\ea6e";
$ToolbarIcon-ModH3-path4: "\ea6f";
$ToolbarIcon-ModH3-path5: "\ea70";
$ToolbarIcon-ModH3-path6: "\ea71";
$ToolbarIcon-ModH3-path7: "\ea72";
$ToolbarIcon-ModH3-path8: "\ea73";
$ToolbarIcon-ModH3-path9: "\ea74";
$ToolbarIcon-ModH3-path10: "\ea75";
$ToolbarIcon-ModH3-path11: "\ea76";
$ToolbarIcon-ModH2Ext-path1: "\ea77";
$ToolbarIcon-ModH2Ext-path2: "\ea78";
$ToolbarIcon-ModH2Ext-path3: "\ea79";
$ToolbarIcon-ModH2Ext-path4: "\ea7a";
$ToolbarIcon-ModH2Ext-path5: "\ea7b";
$ToolbarIcon-ModH2Ext-path6: "\ea7c";
$ToolbarIcon-ModH2Ext-path7: "\ea7d";
$ToolbarIcon-ModH2Ext-path8: "\ea7e";
$ToolbarIcon-ModV6Ext-path1: "\ea7f";
$ToolbarIcon-ModV6Ext-path2: "\ea80";
$ToolbarIcon-ModV6Ext-path3: "\ea81";
$ToolbarIcon-ModV6Ext-path4: "\ea82";
$ToolbarIcon-ModV6Ext-path5: "\ea83";
$ToolbarIcon-ModV6Ext-path6: "\ea84";
$ToolbarIcon-ModV6Ext-path7: "\ea85";
$ToolbarIcon-ModV6Ext-path8: "\ea86";
$ToolbarIcon-ModV6Ext-path9: "\ea87";
$ToolbarIcon-ModV6Ext-path10: "\ea88";
$ToolbarIcon-ModV6Ext-path11: "\ea89";
$ToolbarIcon-ModV6Ext-path12: "\ea8a";
$ToolbarIcon-ModV6Ext-path13: "\ea8b";
$ToolbarIcon-ModV6Ext-path14: "\ea8c";
$ToolbarIcon-ModV6Ext-path15: "\ea8d";
$ToolbarIcon-ModV6Ext-path16: "\ea8e";
$ToolbarIcon-ModV6Ext-path17: "\ea8f";
$ToolbarIcon-ModV6Ext-path18: "\ea90";
$ToolbarIcon-ModV6Ext-path19: "\ea91";
$ToolbarIcon-ModV6Ext-path20: "\ea92";
$ToolbarIcon-ModV6-path1: "\ea93";
$ToolbarIcon-ModV6-path2: "\ea94";
$ToolbarIcon-ModV6-path3: "\ea95";
$ToolbarIcon-ModV6-path4: "\ea96";
$ToolbarIcon-ModV6-path5: "\ea97";
$ToolbarIcon-ModV6-path6: "\ea98";
$ToolbarIcon-ModV6-path7: "\ea99";
$ToolbarIcon-ModV6-path8: "\ea9a";
$ToolbarIcon-ModV6-path9: "\ea9b";
$ToolbarIcon-ModV6-path10: "\ea9c";
$ToolbarIcon-ModV6-path11: "\ea9d";
$ToolbarIcon-ModV6-path12: "\ea9e";
$ToolbarIcon-ModV6-path13: "\ea9f";
$ToolbarIcon-ModV6-path14: "\eaa0";
$ToolbarIcon-ModV6-path15: "\eaa1";
$ToolbarIcon-ModV6-path16: "\eaa2";
$ToolbarIcon-ModV6-path17: "\eaa3";
$ToolbarIcon-ModV6-path18: "\eaa4";
$ToolbarIcon-ModV6-path19: "\eaa5";
$ToolbarIcon-ModV6-path20: "\eaa6";
$ToolbarIcon-ModV5-path1: "\eaa7";
$ToolbarIcon-ModV5-path2: "\eaa8";
$ToolbarIcon-ModV5-path3: "\eaa9";
$ToolbarIcon-ModV4-path1: "\eaaa";
$ToolbarIcon-ModV4-path2: "\eaab";
$ToolbarIcon-ModV4-path3: "\eaac";
$ToolbarIcon-ModV4-path4: "\eaad";
$ToolbarIcon-ModV4-path5: "\eaae";
$ToolbarIcon-ModV4-path6: "\eaaf";
$ToolbarIcon-ModV4-path7: "\eab0";
$ToolbarIcon-ModV4-path8: "\eab1";
$ToolbarIcon-ModV4-path9: "\eab2";
$ToolbarIcon-ModV4-path10: "\eab3";
$ToolbarIcon-ModV4-path11: "\eab4";
$ToolbarIcon-ModV4-path12: "\eab5";
$ToolbarIcon-ModV4-path13: "\eab6";
$ToolbarIcon-ModV4-path14: "\eab7";
$ToolbarIcon-modV3-path1: "\eab8";
$ToolbarIcon-modV3-path2: "\eab9";
$ToolbarIcon-modV3-path3: "\eaba";
$ToolbarIcon-modV3-path4: "\eabb";
$ToolbarIcon-modV3-path5: "\eabc";
$ToolbarIcon-modV3-path6: "\eabd";
$ToolbarIcon-modV3-path7: "\eabe";
$ToolbarIcon-modV3-path8: "\eabf";
$ToolbarIcon-modV3-path9: "\eac0";
$ToolbarIcon-modV3-path10: "\eac1";
$ToolbarIcon-modV3-path11: "\eac2";
$ToolbarIcon-modV3Ext-path1: "\eac3";
$ToolbarIcon-modV3Ext-path2: "\eac4";
$ToolbarIcon-modV3Ext-path3: "\eac5";
$ToolbarIcon-modV3Ext-path4: "\eac6";
$ToolbarIcon-modV3Ext-path5: "\eac7";
$ToolbarIcon-modV3Ext-path6: "\eac8";
$ToolbarIcon-modV3Ext-path7: "\eac9";
$ToolbarIcon-modV3Ext-path8: "\eaca";
$ToolbarIcon-modV3Ext-path9: "\eacb";
$ToolbarIcon-modV3Ext-path10: "\eacc";
$ToolbarIcon-modV3Ext-path11: "\eacd";
$ToolbarIcon-ModV2Ext-path1: "\eace";
$ToolbarIcon-ModV2Ext-path2: "\eacf";
$ToolbarIcon-ModV2Ext-path3: "\ead0";
$ToolbarIcon-ModV2Ext-path4: "\ead1";
$ToolbarIcon-ModV2Ext-path5: "\ead2";
$ToolbarIcon-ModV2Ext-path6: "\ead3";
$ToolbarIcon-ModV2Ext-path7: "\ead4";
$ToolbarIcon-ModV2Ext-path8: "\ead5";
$ToolbarIcon-ModV2Ext-path9: "\ead6";
$ToolbarIcon-ModV2Ext-path10: "\ead7";
$ToolbarIcon-ModV2Ext-path11: "\ead8";
$ToolbarIcon-Traufe-path1: "\ead9";
$ToolbarIcon-Traufe-path2: "\eada";
$ToolbarIcon-Traufe-path3: "\eadb";
$ToolbarIcon-First-path1: "\eadc";
$ToolbarIcon-First-path2: "\eadd";
$ToolbarIcon-First-path3: "\eade";
$ToolbarIcon-ZMessen-path1: "\eadf";
$ToolbarIcon-ZMessen-path2: "\eae0";
$ToolbarIcon-MessenFrei-path1: "\eae1";
$ToolbarIcon-MessenFrei-path2: "\eae2";
$ToolbarIcon-MessenZFrei: "\eae3";
$ToolbarIcon-Grad: "\eae4";
$ToolbarIcon-ThermalH-path1: "\eae5";
$ToolbarIcon-ThermalH-path2: "\eae6";
$ToolbarIcon-ThermalH-path3: "\eae7";
$ToolbarIcon-FensterHLeer-path1: "\eae8";
$ToolbarIcon-FensterHLeer-path2: "\eae9";
$ToolbarIcon-ThermalV-path1: "\eaea";
$ToolbarIcon-ThermalV-path2: "\eaeb";
$ToolbarIcon-ThermalV-path3: "\eaec";
$ToolbarIcon-FensterV-path1: "\eaed";
$ToolbarIcon-FensterV-path2: "\eaee";
$ToolbarIcon-FensterV-path3: "\eaef";
$ToolbarIcon-FensterV-path4: "\eaf0";
$ToolbarIcon-FensterV-path5: "\eaf1";
$ToolbarIcon-FensterV-path6: "\eaf2";
$ToolbarIcon-FensterVLeer-path1: "\eaf3";
$ToolbarIcon-FensterVLeer-path2: "\eaf4";
$ToolbarIcon-Delete-path1: "\eaf5";
$ToolbarIcon-Delete-path2: "\eaf6";
$ToolbarIcon-DelHGuide-path1: "\eaf7";
$ToolbarIcon-DelHGuide-path2: "\eaf8";
$ToolbarIcon-DelHGuide-path3: "\eaf9";
$ToolbarIcon-DelHGuide-path4: "\eafa";
$ToolbarIcon-HGuide-path1: "\eafb";
$ToolbarIcon-HGuide-path2: "\eafc";
$ToolbarIcon-DelVGuide-path1: "\eafd";
$ToolbarIcon-DelVGuide-path2: "\eafe";
$ToolbarIcon-DelVGuide-path3: "\eaff";
$ToolbarIcon-DelVGuide-path4: "\eb00";
$ToolbarIcon-DelVGuide-path5: "\eb01";
$ToolbarIcon-DelMod-path1: "\eb02";
$ToolbarIcon-DelMod-path2: "\eb03";
$ToolbarIcon-DelMod-path3: "\eb04";
$ToolbarIcon-DelMod-path4: "\eb05";
$ToolbarIcon-DelMod-path5: "\eb06";
$ToolbarIcon-Frage: "\eb07";
$ToolbarIcon-NoMod-path1: "\eb08";
$ToolbarIcon-NoMod-path2: "\eb09";
$ToolbarIcon-NoMod-path3: "\eb0a";
$ToolbarIcon-NoMod-path4: "\eb0b";
$ToolbarIcon-ModTransparent-path1: "\eb0c";
$ToolbarIcon-ModTransparent-path2: "\eb0d";
$ToolbarIcon-ModTransparent-path3: "\eb0e";
$ToolbarIcon-ModTransparent-path4: "\eb0f";
$ToolbarIcon-ModTransparent-path5: "\eb10";
$ToolbarIcon-ModTransparent-path6: "\eb11";
$ToolbarIcon-Messen: "\eb12";
$ToolbarIcon-Drehen-path1: "\eb13";
$ToolbarIcon-Drehen-path2: "\eb14";
$ToolbarIcon-Drehen-path3: "\eb15";
$ToolbarIcon-Cube: "\eb16";
$ToolbarIcon-Lupe: "\eb17";
$ToolbarIcon-HandMove: "\eb18";
$ToolbarIcon-HandPoint: "\eb19";
$ToolbarIcon-Hand: "\eb1a";
$ToolbarIcon-Hamburger: "\eb1b";
$ToolbarIcon-Verbot: "\eb1c";
$ToolbarIcon-ModElevation-path1: "\eb1d";
$ToolbarIcon-ModElevation-path2: "\eb1e";
$ToolbarIcon-ModElevation-path3: "\eb1f";
$ToolbarIcon-ModElevation-path4: "\eb20";
$ToolbarIcon-ModElevation-path5: "\eb21";
$ToolbarIcon-ModElevation-path6: "\eb22";
$ToolbarIcon-InsertCopy-path1: "\eb23";
$ToolbarIcon-InsertCopy-path2: "\eb24";
$ToolbarIcon-InsertCopy-path3: "\eb25";
$ToolbarIcon-InsertCopy-path4: "\eb26";
$ToolbarIcon-InsertCopy-path5: "\eb27";
$ToolbarIcon-InsertCopy-path6: "\eb28";
$ToolbarIcon-InsertCopy-path7: "\eb29";
$ToolbarIcon-InsertCopy-path8: "\eb2a";
$ToolbarIcon-InsertCopy-path9: "\eb2b";
$ToolbarIcon-InsertCopy-path10: "\eb2c";
$ToolbarIcon-InsertSelection-path1: "\eb2d";
$ToolbarIcon-InsertSelection-path2: "\eb2e";
$ToolbarIcon-InsertSingleMod-path1: "\eb2f";
$ToolbarIcon-InsertSingleMod-path2: "\eb30";
$ToolbarIcon-InsertSingleMod-path3: "\eb31";
$ToolbarIcon-InsertSingleMod-path4: "\eb32";
$ToolbarIcon-ModuleH-path1: "\eb33";
$ToolbarIcon-ModuleH-path2: "\eb34";
$ToolbarIcon-ModuleH-path3: "\eb35";
$ToolbarIcon-ModulV-path1: "\eb36";
$ToolbarIcon-ModulV-path2: "\eb37";
$ToolbarIcon-ModulV-path3: "\eb38";
$ToolbarIcon-select: "\eb39";

