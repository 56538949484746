$icomoon-font-family: "SPT-MainNav-Icons";
$icomoon-font-path: "../../../../styles/webfonts/custom/MainNavIcon";

$MainNavIcon-Carport: "\e97c";
$MainNavIcon-Freiland: "\e97d";
$MainNavIcon-Anordnung-path1: "\e900";
$MainNavIcon-Anordnung-path2: "\e901";
$MainNavIcon-Anordnung-path3: "\e902";
$MainNavIcon-Anordnung-path4: "\e903";
$MainNavIcon-Anordnung-path5: "\e904";
$MainNavIcon-Anordnung-path6: "\e905";
$MainNavIcon-Anordnung-path7: "\e906";
$MainNavIcon-Anordnung-path8: "\e907";
$MainNavIcon-Anordnung-path9: "\e908";
$MainNavIcon-Anordnung-path10: "\e909";
$MainNavIcon-Anordnung-path11: "\e90a";
$MainNavIcon-Anordnung-path12: "\e90b";
$MainNavIcon-Anordnung-path13: "\e90c";
$MainNavIcon-Anordnung-path14: "\e90d";
$MainNavIcon-Anordnung-path15: "\e90e";
$MainNavIcon-Anordnung-path16: "\e90f";
$MainNavIcon-Anordnung-path17: "\e910";
$MainNavIcon-Anordnung-path18: "\e911";
$MainNavIcon-Anordnung-path19: "\e912";
$MainNavIcon-Anordnung-path20: "\e913";
$MainNavIcon-Anordnung-path21: "\e914";
$MainNavIcon-Anordnung-path22: "\e915";
$MainNavIcon-Anordnung-path23: "\e916";
$MainNavIcon-Anordnung-path24: "\e917";
$MainNavIcon-Anordnung-path25: "\e918";
$MainNavIcon-Ausgabe-path1: "\e919";
$MainNavIcon-Ausgabe-path2: "\e91a";
$MainNavIcon-Ausgabe-path3: "\e91b";
$MainNavIcon-Ausgabe-path4: "\e91c";
$MainNavIcon-Ausgabe-path5: "\e91d";
$MainNavIcon-CAD-Plan-path1: "\e91e";
$MainNavIcon-CAD-Plan-path2: "\e91f";
$MainNavIcon-CAD-Plan-path3: "\e920";
$MainNavIcon-CAD-Plan-path4: "\e921";
$MainNavIcon-CAD-Plan-path5: "\e922";
$MainNavIcon-CAD-Plan-path6: "\e923";
$MainNavIcon-CAD-Plan-path7: "\e924";
$MainNavIcon-CAD-Plan-path8: "\e925";
$MainNavIcon-CAD-Plan-path9: "\e926";
$MainNavIcon-CAD-Plan-path10: "\e927";
$MainNavIcon-CAD-Plan-path11: "\e928";
$MainNavIcon-CAD-Plan-path12: "\e929";
$MainNavIcon-CAD-Plan-path13: "\e92a";
$MainNavIcon-CAD-Plan-path14: "\e92b";
$MainNavIcon-CAD-Plan-path15: "\e92c";
$MainNavIcon-CAD-Plan-path16: "\e92d";
$MainNavIcon-CAD-Plan-path17: "\e92e";
$MainNavIcon-CAD-Plan-path18: "\e92f";
$MainNavIcon-CAD-Plan-path19: "\e930";
$MainNavIcon-CAD-Plan-path20: "\e931";
$MainNavIcon-CAD-Plan-path21: "\e932";
$MainNavIcon-CAD-Plan-path22: "\e933";
$MainNavIcon-CAD-Plan-path23: "\e934";
$MainNavIcon-CAD-Plan-path24: "\e935";
$MainNavIcon-CAD-Plan-path25: "\e936";
$MainNavIcon-CAD-Plan-path26: "\e937";
$MainNavIcon-CAD-Plan-path27: "\e938";
$MainNavIcon-CAD-Plan-path28: "\e939";
$MainNavIcon-CAD-Plan-path29: "\e93a";
$MainNavIcon-CAD-Plan-path30: "\e93b";
$MainNavIcon-CAD-Plan-path31: "\e93c";
$MainNavIcon-CAD-Plan-path32: "\e93d";
$MainNavIcon-CAD-Plan-path33: "\e93e";
$MainNavIcon-CAD-Plan-path34: "\e93f";
$MainNavIcon-CAD-Plan-path35: "\e940";
$MainNavIcon-CAD-Plan-path36: "\e941";
$MainNavIcon-CAD-Plan-path37: "\e942";
$MainNavIcon-CAD-Plan-path38: "\e943";
$MainNavIcon-CAD-Plan-path39: "\e944";
$MainNavIcon-CAD-Plan-path40: "\e945";
$MainNavIcon-CAD-Plan-path41: "\e946";
$MainNavIcon-CAD-Plan-path42: "\e947";
$MainNavIcon-CAD-Plan-path43: "\e948";
$MainNavIcon-CAD-Plan-path44: "\e949";
$MainNavIcon-CAD-Plan-path45: "\e94a";
$MainNavIcon-CAD-Plan-path46: "\e94b";
$MainNavIcon-CAD-Plan-path47: "\e94c";
$MainNavIcon-CAD-Plan-path48: "\e94d";
$MainNavIcon-CAD-Plan-path49: "\e94e";
$MainNavIcon-CAD-Plan-path50: "\e94f";
$MainNavIcon-CAD-Plan-path51: "\e950";
$MainNavIcon-CAD-Plan-path52: "\e951";
$MainNavIcon-CAD-Plan-path53: "\e952";
$MainNavIcon-CAD-Plan-path54: "\e953";
$MainNavIcon-CAD-Plan-path55: "\e954";
$MainNavIcon-CAD-Plan-path56: "\e955";
$MainNavIcon-CAD-Plan-path57: "\e956";
$MainNavIcon-CAD-Plan-path58: "\e957";
$MainNavIcon-CAD-Plan-path59: "\e958";
$MainNavIcon-CAD-Plan-path60: "\e959";
$MainNavIcon-CAD-Plan-path61: "\e95a";
$MainNavIcon-CAD-Plan-path62: "\e95b";
$MainNavIcon-CAD-Plan-path63: "\e95c";
$MainNavIcon-CAD-Plan-path64: "\e95d";
$MainNavIcon-CAD-Plan-path65: "\e95e";
$MainNavIcon-Dach-path1: "\e95f";
$MainNavIcon-Dach-path2: "\e960";
$MainNavIcon-E-Designer-path1: "\e961";
$MainNavIcon-E-Designer-path2: "\e962";
$MainNavIcon-E-Designer-path3: "\e963";
$MainNavIcon-E-Designer-path4: "\e964";
$MainNavIcon-E-Designer-path5: "\e965";
$MainNavIcon-Elektrik-path1: "\e966";
$MainNavIcon-Elektrik-path2: "\e967";
$MainNavIcon-Home: "\e968";
$MainNavIcon-Konstruktion: "\e969";
$MainNavIcon-Projekt-path1: "\e96a";
$MainNavIcon-Projekt-path2: "\e96b";
$MainNavIcon-Projekt-path3: "\e96c";
$MainNavIcon-PV-Module-path1: "\e96d";
$MainNavIcon-PV-Module-path2: "\e96e";
$MainNavIcon-PV-Module-path3: "\e96f";
$MainNavIcon-Statik: "\e970";
$MainNavIcon-Wirtschaftlichkeit-path1: "\e971";
$MainNavIcon-Wirtschaftlichkeit-path2: "\e972";
$MainNavIcon-Wirtschaftlichkeit-path3: "\e973";
$MainNavIcon-Wirtschaftlichkeit-path4: "\e974";
$MainNavIcon-Wirtschaftlichkeit-path5: "\e975";
$MainNavIcon-Wirtschaftlichkeit-path6: "\e976";
$MainNavIcon-Wirtschaftlichkeit-path7: "\e977";
$MainNavIcon-Wirtschaftlichkeit-path8: "\e978";
$MainNavIcon-Wirtschaftlichkeit-path9: "\e979";
$MainNavIcon-Wirtschaftlichkeit-path10: "\e97a";
$MainNavIcon-Wirtschaftlichkeit-path11: "\e97b";

