﻿$pulseScaleOut: 1.22;

@import "../../node_modules/materialize-css/sass/components/pulse";

.pulse::before {
    z-index: auto !important;
}

@keyframes pulse-animation {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    50% {
        opacity: 0;
        transform: scale($pulseScaleOut);
    }

    100% {
        opacity: 0;
        transform: scale($pulseScaleOut);
    }
}

/* extend pulse class with margin 1 to prevent pulse scale display error */
/*.pulse-m-1 {
    @extend .pulse, .m-1;
}*/