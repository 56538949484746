﻿/*@import "variables";*/
@import "sidebar";
@import "toolbar";
@import "form";
@import "modal";
@import "mapDrawer";
@import "client3dEditor";

/*sunify*/
.btn-stepper-disabled {
    width: 80px;
    cursor: default;
}

.btn.btn-outline-sunify-secondary {
    @include button-outline-variant($primary-sunify, $color-hover: $primary-sunify, $active-background: $secondary-sunify, $active-border: $primary-sunify);
}

.rounded-medium {
    @extend .rounded;
}

.default-font-size {
    font-size: 1rem;
}

.text-request {
    color: white;
}

.request-custom-button {
    border-color: color-yiq(#f39200) !important;
}

.request-custom-button {
    border-color: color-yiq(#f39200) !important;
}

/* stepper */

.bs-stepper .step.active .step-trigger {
    color: var(--primary) !important;
}

.clickable-map-bg {
    background-color: white;
}

footer {
    // TODO sticky-bottom? like sticky-top in navbar
    z-index: 1020;
}

/* background image */

.bg {
    background-position: 77% 50% !important;
}

.bg {
    // background: url(<image-data>); pass image-data as style
    height: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

.bg::before {
    background-color: var(--primary);
    opacity: 0.6;
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    /*z-index: -1;*/
}

// row-cols-2 row-cols-md-3 row-col-lg-3-custom row-cols-xl-6 
// Five columns from medium breakpoint up
.row-cols-consumer-profile {
    // Three columns to start
    @include row-cols(2);

    @include media-breakpoint-up(md) {
        @include row-cols(3);
    }
    // from medium breakpoint up
    @include media-breakpoint-up(md) {
        @include row-cols(3);
    }

    @include media-breakpoint-up(lg) {
        @include row-cols(3);
    }

    @media (min-width: 1300px) {
        @include row-cols(6);
    }
}

.cursor-pointer {
    cursor: pointer;
}


.clear-append-button {
    @extend .bg-transparent, .pl-0, .font-weight-bold;
    width: 50px;
}
/* chart style */
.chart-canvas-custom {
    min-width: 460px;
    width: 100%;
}

.chart-container-custom {
    overflow: auto;
}
/* electric radio button element */
.btn-radio-electric {
    @extend .p-2;
}
/* Pulse */
@-webkit-keyframes hvr-pulse {
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

@keyframes hvr-pulse {
    25% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    75% {
        -webkit-transform: scale(0.9);
        transform: scale(0.9);
    }
}

.hvr-pulse {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}

.hvr-pulse:hover, .hvr-pulse:focus, .hvr-pulse:active {
    -webkit-animation-name: hvr-pulse;
    animation-name: hvr-pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.main-layout-root {
    /*padding-top: 4.75rem;*/
    /*padding-bottom: 3.063rem;*/
}

.footer-padding {
    padding: .75rem !important;
}

@include media-breakpoint-down(sm) {
    .footer-padding {
        padding: .25rem !important;
    }
}

.stepper-layout-root {
    /*padding-top: 4.75rem;*/
    /*padding-top: 3.5rem;*/
    /* padding-bottom: 3.438rem;*/
}
/*main > .container {
    padding: 60px 15px 0;
}*/
.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: $nav-height;
    }
}

/* bug fixes */
.fix-background-color {
    background: inherit;
}

/*layout*/
.footer-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

.main-scrollable {
    flex: 1;
    overflow: auto;
}
/* card border */
.card-border-bold {
    border: 4px solid;
}
/*stepper progress*/
.progress-stepper {
    width: 42%;
    height: 20px;
}
/*bootstrap list item, because of semantiv ui loader => not dimmed */
/*full border in card*/
.list-group-item {
    /*position: unset;*/
    background-color: unset;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: transparent;
}
/*bootstrap modal*/
.modal-backdrop {
    background: none;
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
}
/*bootstrap switch or checkbox*/
.custom-control-input:not(.no-validate):checked ~ .custom-control-label::before {
    background-color: #28a745;
    border-color: #28a745;
}

.custom-radio .custom-control-input:not(.no-validate):disabled:checked ~ .custom-control-label::before {
    background-color: rgba(40, 167, 69,.5);
}

.custom-switch .custom-control-input:not(.no-validate):disabled:checked ~ .custom-control-label::before {
    background-color: rgba(40, 167, 69,.5);
}
/*carousel*/
.carousel-caption-full {
    right: 0%;
    bottom: 0px;
    left: 0%;
    padding-top: 0px;
    padding-bottom: 0px;
    z-index: 10;
}

.carousel-indicators li {
    background-color: var(--customer) !important; // $main-color
}

@include media-breakpoint-down(sm) {
    .carousel-control-prev-icon {
        /*fill color changed*/
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='002e5f' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
        /*padding: 20px;*/
    }

    .carousel-control-next-icon {
        /*fill color changed*/
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='002e5f' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
        /*padding: 20px;*/
    }
}

@include media-breakpoint-up(md) {
    .carousel-control-prev-icon {
        /*fill color changed*/
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='002e5f' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
        padding: 20px;
    }

    .carousel-control-next-icon {
        /*fill color changed*/
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='002e5f' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
        padding: 20px;
    }
}


@include media-breakpoint-up(xl) {
    .btn-group-responsive > .btn {
        @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-xl, $btn-line-height-lg, $btn-border-radius-lg);
    }

    .h-responsive {
        font-size: 50pt; //$h4-font-size;
    }

    .h-sub-responsive {
        font-size: 36pt; //$h5-font-size;
    }

    .pb-responsive2 {
        padding-bottom: 2rem !important;
    }

    .pb-responsive2small {
        padding-bottom: 1rem !important;
    }
}

@include media-breakpoint-down(lg) {
    .btn-group-responsive > .btn {
        @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
    }

    .h-responsive {
        font-size: 36pt; //$h4-font-size;
    }

    .h-sub-responsive {
        font-size: 25pt; //$h5-font-size;
    }

    .pb-responsive2 {
        padding-bottom: 2rem !important;
    }

    .pb-responsive2small {
        padding-bottom: 1rem !important;
    }
}

@include media-breakpoint-down(md) {
    .btn-group-responsive > .btn, .btn-responsive {
        @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
    }

    .h-responsive {
        font-size: 30pt; //$h5-font-size;
    }

    .h-sub-responsive {
        font-size: 20pt; //$h5-font-size;
    }

    .pb-responsive2 {
        padding-bottom: 1.5rem !important;
    }

    .pb-responsive2small {
        padding-bottom: 1.5rem !important;
    }
}

@include media-breakpoint-down(xs) {
    .btn-responsive {
        @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
    }
}
/*responsive*/
@media (max-width: 500px) {
    .btn-group-responsive > .btn {
        @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
    }

    .h-responsive {
        font-size: 20pt;
    }

    .h-sub-responsive {
        font-size: 15pt; //$h5-font-size;
    }

    .pb-responsive2 {
        padding-bottom: 1rem !important;
    }

    .pb-responsive2small {
        padding-bottom: .5rem !important;
    }
}

@include media-breakpoint-down(xs) {
    .p-responsive {
        padding: .1rem !important;
    }
}

@mixin mobile-friendly {
    /*.stepper-layout-root {
        padding-top: 3.625rem;
        padding-bottom: 2.875rem;
    }*/
    .navbar {
        padding: .25rem 1rem;
    }

    #app-logo {
        height: 40px;
        /*width: $logoWith;*/ /*40px*/
    }

    .navbar-toggler i {
        font-size: unset;
    }
}

.custom-down-xs {
    height: 100%;
}

@include media-breakpoint-up(sm) {
    .col-sm-up-d-none {
        display: none;
    }
}

@include media-breakpoint-down(xs) {
    .bg-container::before {
        background-image: none;
    }

    .col-xs-down-12 {
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
    }

    .custom-down-xs {
        height: auto !important;
        margin-top: 1rem !important; /*mt-3*/
    }
}

@include media-breakpoint-down(sm) {
    @include mobile-friendly;
}

@include media-breakpoint-down(md) {
    @include orientation(landscape) {
        @include mobile-friendly;
    }
}
/*@include media-breakpoint-down(sm) {
    .main-layout-root {
        padding-top: 3.625rem;
    }
}*/
/*dropdown*/
.dropdown-toolbar-custom {
    .dropdown-menu {
        min-width: unset;
        padding: 0;
    }

    .dropdown-toggle::after {
        /*transform: rotate(-45deg);
        right: 1px;
        bottom: 1px;
        position: absolute;*/
        content: unset;
    }

    .dropdown-subicon {
        transform: rotate(45deg);
        position: absolute;
        bottom: 0px;
        right: 3px;
    }
}
/*semantiv ui loader*/
.ui.active {
    z-index: 1002 !important;
}
/*collapse nav links*/
.multi-collapse .nav-tabs .nav-link.active {
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
}

.multi-collapse .nav-tabs .nav-link {
    border: unset;
}

.multi-collapse .nav-tabs .nav-link:first-child {
    border-left: 0px;
}

.nav-link {
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
}
/*toolbar collapse menu slide from left to right*/
/*.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}*/
.collapsing.slide {
    -webkit-transition-property: width, visibility;
    transition-property: width, visibility;
    width: 0;
    height: auto;
}

.roof-slope-custom {
    width: 40px;
}
/* form controll small */
/*.form-control-sm-custom {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;*/
/* border-radius: .2rem; */
/*}*/
/* slider custom css*/
.slider-row-padding {
    @extend .pb-2;
}

.slider-col-padding {
    @extend .pb-5;
}

@media (max-width: 520px) {
    .slider-row-padding {
        padding-bottom: 3rem !important;
    }

    .slider-col-padding {
        padding-bottom: 3rem !important;
    }
}
/* tooltip */
.tooltip.show {
    opacity: 1;
}

@include media-breakpoint-down(xs) {
    /*.input-group-sm*/
    .input-group-responsive > .custom-select, .input-group-responsive > .form-control:not(textarea) {
        height: calc(1.5em + 1rem + 2px);
    }

    .input-group-responsive > .custom-select, .input-group-responsive > .form-control, .input-group-responsive > .input-group-append > .btn, .input-group-responsive > .input-group-append > .input-group-text, .input-group-responsive > .input-group-prepend > .btn, .input-group-responsive > .input-group-prepend > .input-group-text {
        padding: .5rem .5rem;
        font-size: .875rem;
        line-height: 1.5;
        border-radius: .2rem;
    }
}


@include media-breakpoint-down(xs) {
    .navbar-brand {
        font-size: .875rem;
    }
}

@include media-breakpoint-down(xs) {
    .progress-stepper {
        height: 1rem;
    }
}

@include media-breakpoint-down(xs) {
    .bs-stepper .step-trigger {
        font-size: .875rem;
    }
}


@include media-breakpoint-down(xs) {
    .navbar {
        padding: .25rem .25em;
    }
}

.map-padding-correction {
    padding-bottom: 42px;
}
/*@include media-breakpoint-down(xs) {
    .map-padding-correction {
        padding-bottom: 2.438rem;
    }
}*/
@include media-breakpoint-down(xs) {
    .form-responsive {

        .form-group {
            margin-bottom: .5rem;
        }

        .form-control {
            /*.form-control-sm*/
            height: calc(1.5em + .5rem + 2px);
            padding: .25rem .5rem;
            font-size: .875rem;
            line-height: 1.5;
            border-radius: .2rem;
        }

        label {
            font-size: .875rem;
        }
    }
}
/*form size*/
.form-sm {
    .form-group {
        margin-bottom: .5rem;
    }
}
/* semantic-ui loader */
/* loader overrides */
.ui.dimmer {
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
    padding: 1em;
    background-color: rgba(0,0,0,.85);
    line-height: 1;
    animation-fill-mode: both;
    animation-duration: .5s;
    transition: background-color .5s linear;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    will-change: opacity;
}

.ui.inverted.dimmer {
    background-color: rgba(255,255,255,.85);
}
/*modal remove disgusting border*/
.modal-content {
    border: 0 !important;
}
/* semnatic ui checkbox */
.ui.toggle.checkbox input:focus:checked ~ .box:before, .ui.toggle.checkbox input:focus:checked ~ label:before {
    //@extend .bg-success
    background-color: #28a745 !important;
}

.ui.toggle.checkbox input:checked ~ .box:before, .ui.toggle.checkbox input:checked ~ label:before {
    //@extend .bg-success
    background-color: #28a745 !important;
}

.ui.toggle.checkbox .box:after, .ui.toggle.checkbox label:after {
    height: 1.25rem;
}

.ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
    height: 1.25rem;
}
/* custom bootstrap no-gutters*/
.small-gutters > .col, .small-gutters > [class*=col-] {
    padding-right: 4px;
    padding-left: 4px;
}

.small-gutters > .col:first-child, .small-gutters > [class*=col-]:first-child {
    padding-left: 0px;
}

.small-gutters > .col:last-child, .small-gutters > [class*=col-]:last-child {
    padding-right: 0px;
}

.small-gutters {
    margin-right: 0;
    margin-left: 0;
}
/*table list items*/
.table.list-items td, .table.list-items th {
    padding: .75rem 1.25rem;
}

.d-flex-center {
    //@extend .d-flex, .justify-content-center, .align-items-center;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
/*chart custom style*/
/*.chart-custom canvas {
    max-height: 290px !important;
    width: auto !important;
}

.chart-custom {
    @extend .d-flex-center;
}*/
/*button customer outline with background */
.btn-outline-customer-white {
    background-color: rgba(255, 255, 255, 1);
}
/*background white transparent*/
.bg-white-transparent-95 {
    background-color: rgba(255, 255, 255, 0.95) !important;
}

.bg-white-transparent-5 {
    background-color: rgba(255, 255, 255, 0.5) !important;
}
/* responsive */
@include media-breakpoint-down(sm) {
    .text-responsive {
        font-size: smaller;
    }

    .col-xxs-12-center {
        text-align: center !important;
        width: 100%;
    }
}


@mixin createClassFromSteps($prefix, $property, $min, $max, $step) {
    @for $i from $min through $max {
        #{$prefix}-#{$i} {
            #{$property}: $i * $step;
        }
    }
}
/*opacity*/
@include createClassFromSteps('.opacity', opacity, 0, 10, 0.1);
/* form placeholder */
.form-control::placeholder {
    color: lightgray;
}
/* Chrome, Firefox, Opera*/
:-ms-input-placeholder.form-control {
    color: lightgray;
}
/* Internet Explorer*/
.form-control::-ms-input-placeholder {
    color: lightgray;
}
/* Microsoft Edge*/
.form-control::-moz-placeholder {
    color: lightgray;
}

.loginForm {
    max-width: 400px;
}
/* bugfix popper dropdown visible safari iOS */
.popper-dropdown-safari-ios-fix {
    overflow-x: unset !important;
    overflow-y: unset !important;
}
/* bugfix 16px inputfields prevent auto-zoom on safari iOS */
input {
    font-size: 1rem !important;
}

.remove-line-height-semnatic-ui .ui.button {
    line-height: unset !important;
}
