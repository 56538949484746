﻿@import "variables";

@import "bootstrap-variables-custom";



//-------- START Bootstrap v4.6.0
//@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

//--- start mixing - custom mixins, only form mixing is changed
//@import "../../node_modules/bootstrap/scss/mixins";
// Toggles
//
// Used in conjunction with global variables to enable certain theme features.

// Vendor
@import "../../node_modules/bootstrap/scss/vendor/rfs";

// Deprecate
@import "../../node_modules/bootstrap/scss/mixins/deprecate";

// Utilities
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../node_modules/bootstrap/scss/mixins/hover";
@import "../../node_modules/bootstrap/scss/mixins/image";
@import "../../node_modules/bootstrap/scss/mixins/badge";
@import "../../node_modules/bootstrap/scss/mixins/resize";
@import "../../node_modules/bootstrap/scss/mixins/screen-reader";
@import "../../node_modules/bootstrap/scss/mixins/size";
@import "../../node_modules/bootstrap/scss/mixins/reset-text";
@import "../../node_modules/bootstrap/scss/mixins/text-emphasis";
@import "../../node_modules/bootstrap/scss/mixins/text-hide";
@import "../../node_modules/bootstrap/scss/mixins/text-truncate";
@import "../../node_modules/bootstrap/scss/mixins/visibility";

// Components
@import "../../node_modules/bootstrap/scss/mixins/alert";
@import "../../node_modules/bootstrap/scss/mixins/buttons";
@import "../../node_modules/bootstrap/scss/mixins/caret";
@import "../../node_modules/bootstrap/scss/mixins/pagination";
@import "../../node_modules/bootstrap/scss/mixins/lists";
@import "../../node_modules/bootstrap/scss/mixins/list-group";
@import "../../node_modules/bootstrap/scss/mixins/nav-divider";
@import "./bootstrap-theme/forms";
@import "../../node_modules/bootstrap/scss/mixins/table-row";

// Skins
@import "../../node_modules/bootstrap/scss/mixins/background-variant";
@import "../../node_modules/bootstrap/scss/mixins/border-radius";
@import "../../node_modules/bootstrap/scss/mixins/box-shadow";
@import "../../node_modules/bootstrap/scss/mixins/gradients";
@import "../../node_modules/bootstrap/scss/mixins/transition";

// Layout
@import "../../node_modules/bootstrap/scss/mixins/clearfix";
@import "../../node_modules/bootstrap/scss/mixins/grid-framework";
@import "../../node_modules/bootstrap/scss/mixins/grid";
@import "../../node_modules/bootstrap/scss/mixins/float";
//--- end mixing


@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";
//-------- END Bootstrap

@import "../../node_modules/select2/dist/css/select2";
@import "../../node_modules/shepherd.js/dist/css/shepherd";

@import "./icoMoon/ToolbarIcon/style";
@import "./icoMoon/MainNavIcon/style";

@mixin orientation($direction) {

    $orientation-landscape: "(orientation:landscape)";
    $orientation-portrait: "(orientation:portrait)";

    @if $direction == landscape {
        @media #{$orientation-landscape} {
            @content;
        }
    }

    @if $direction == portrait {
        @media #{$orientation-portrait} {
            @content;
        }
    }
}

$fa-font-path: '../../../webfonts' !default;
/*@import "../../node_modules/@fortawesome/fontawesome-free/css/all.min";*/
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/v4-shims";

@import "../../node_modules/bs-stepper/dist/css/bs-stepper";

// materialize
@import "_materialize-custom";

@import "../../node_modules/semantic-ui-button/button.min";
@import "../../node_modules/semantic-ui-label/label.min";
@import "../../node_modules/semantic-ui-loader/loader.min";
@import "../../node_modules/semantic-ui-checkbox/checkbox.min";
@import "../../node_modules/semantic-ui-accordion/accordion.min";
@import "../../node_modules/semantic-ui-dropdown/dropdown.min";
@import "../../node_modules/semantic-ui-transition/transition.min";
@import "../../node_modules/semantic-ui-sidebar/sidebar.min";
@import "../../node_modules/semantic-ui-divider/divider.min";
@import "../../node_modules/semantic-ui-shape/shape.min";
@import "../../node_modules/semantic-ui-menu/menu.min";
@import "../../node_modules/semantic-ui-tab/tab.min";
@import "../../node_modules/semantic-ui-input/input.min";

@import "../../node_modules/tabulator-tables/dist/css/tabulator.min";
/*@import "../../node_modules/tabulator-tables/dist/css/semantic-ui/tabulator_semantic-ui.min";*/
@import "../../node_modules/tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min";


$flag-icon-css-path: '../../../flags' !default;
@import "../../node_modules/flag-icon-css/sass/flag-icon";

@import "site";
//@import "CustomThemes/e3000/custom";
@import "bootstrap-custom";
@import "cookieEuAlert";

