﻿/*$logoPath: url(../../content/images/Logo_E3000.png) !default;*/
//$logoWith: 60px !default;

$main-color: #002e5f !default;
$highlight-color: #df2612 !default;
$nav-height: 3.5rem !default !default;

/*social sharing*/
$facebook: #4267B2 !default;
$twitter: #1DA1F2 !default;
$linkedin: #0274b3 !default;

/* sunify colors */
$primary-sunify: #4B00A3;
$secondary-sunify: #2AFE9D;