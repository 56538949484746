﻿/* 
    .was-validated .form-control.invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(.375em + .1875rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

was-validated .form-control.valid {
    border-color: #28a745;
    padding-right: calc(1.5em + .75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right calc(.375em + .1875rem);
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}
*/

/* TODO repair in this classed the background-image
    .was-validated .custom-select:valid, .was-validated .custom-select.valid
    .was-validated .custom-select:invalid, .was-validated .custom-select.invalid
*/

.validation-errors {
    @extend .alert, .alert-danger, .p-3;
    @include list-unstyled()
}

.form-group .validation-message {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #28a745;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.9);
    border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback ,
.was-validated :valid ~ .valid-tooltip ,
.valid ~ .valid-feedback,
.valid ~ .valid-tooltip {
    display: block;
}

.valid-border-color {
    border-color: #28a745;
}

.invalid-border-color {
    border-color: #dc3545;
}

.was-validated .form-control:valid , .was-validated .form-control.valid {
    border-color: #28a745;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus , .was-validated .form-control.valid:focus  {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated textarea.form-control:valid , .was-validated textarea.form-control.valid  {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid , .was-validated .custom-select.valid   {
    border-color: #28a745;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus , .was-validated .custom-select.valid:focus  {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label , .was-validated .form-check-input.valid ~ .form-check-label  {
    color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback ,
.was-validated .form-check-input:valid ~ .valid-tooltip , .was-validated .form-check-input.valid ~ .valid-feedback ,
.form-check-input.valid ~ .valid-tooltip {
    display: block;
}

.was-validated .custom-control-input:not(.no-validate):valid ~ .custom-control-label , .was-validated .custom-control-input:not(.no-validate).valid ~ .custom-control-label  {
    color: #28a745;
}

.was-validated .custom-control-input:not(.no-validate):valid ~ .custom-control-label::before, .was-validated .custom-control-input:not(.no-validate).valid ~ .custom-control-label::before {
    border-color: #28a745;
}

.was-validated .custom-control-input:not(.no-validate):valid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:not(.no-validate).valid:checked ~ .custom-control-label::before {
    border-color: #34ce57;
    background-color: #34ce57;
}

.was-validated .custom-control-input:not(.no-validate):valid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:not(.no-validate).valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:not(.no-validate):valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-control-input:not(.no-validate).valid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .was-validated .custom-file-input.valid ~ .custom-file-label {
    border-color: #28a745;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .was-validated .custom-file-input.valid:focus ~ .custom-file-label {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback´,
.was-validated :invalid ~ .invalid-tooltip ,
.invalid ~ .invalid-feedback,
.invalid ~ .invalid-tooltip {
    display: block;
}

.was-validated .form-control:invalid , .was-validated .form-control.invalid  {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus , .was-validated .form-control.invalid:focus  {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid , .was-validated textarea.form-control.invalid  {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid , .was-validated .custom-select.invalid  {
    border-color: #dc3545;
    padding-right: calc(0.75em + 2.3125rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus , .was-validated .custom-select.invalid:focus  {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label , .was-validated .form-check-input.invalid ~ .form-check-label  {
    color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback ,
.was-validated .form-check-input:invalid ~ .invalid-tooltip , .was-validated .form-check-input.invalid ~ .invalid-feedback ,
.form-check-input.invalid ~ .invalid-tooltip {
    display: block;
}

.was-validated .custom-control-input:not(.no-validate):invalid ~ .custom-control-label , .was-validated .custom-control-input:not(.no-validate).invalid ~ .custom-control-label  {
    color: #dc3545;
}

.was-validated .custom-control-input:not(.no-validate):invalid ~ .custom-control-label::before, .was-validated .custom-control-input:not(.no-validate).invalid ~ .custom-control-label::before {
    border-color: #dc3545;
}

.was-validated .custom-control-input:not(.no-validate):invalid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:not(.no-validate).invalid:checked ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
}

.was-validated .custom-control-input:not(.no-validate):invalid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:not(.no-validate).invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:not(.no-validate):invalid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-control-input:not(.no-validate).invalid:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .was-validated .custom-file-input.invalid ~ .custom-file-label {
    border-color: #dc3545;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .was-validated .custom-file-input.invalid:focus ~ .custom-file-label {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
