﻿.modal-dialog-fullscreen {
    max-width: unset;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.modal-content-fullscreen {
    height: auto;
    min-height: 100%;
    border-radius: 0;
}