@import "variables";

/*CUSTOM begin*/
.transparent[class^="MainNavIcon-"] span:before,
.transparent[class*=" MainNavIcon-"] span:before {
    color: unset !important;
}

.no-transparent[class^="MainNavIcon-"] span:before,
.no-transparent[class*=" MainNavIcon-"] span:before {
    opacity: unset !important;
}
/* CUSTOM end*/

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?689bmu');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?689bmu#iefix') format('embedded-opentype'), url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?689bmu') format('truetype'), url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?689bmu') format('woff'), url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?689bmu##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="MainNavIcon-"], [class*=" MainNavIcon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*font-size: 24px;*/
}

.MainNavIcon-Carport {
    &:before {
        content: $MainNavIcon-Carport;
    }
}

.MainNavIcon-Freiland {
    &:before {
        content: $MainNavIcon-Freiland;
    }
}

.MainNavIcon-Anordnung .path1 {
    &:before {
        content: $MainNavIcon-Anordnung-path1;
        color: rgb(204, 204, 204);
    }
}

.MainNavIcon-Anordnung .path2 {
    &:before {
        content: $MainNavIcon-Anordnung-path2;
        margin-left: -1em;
        color: rgb(77, 77, 77);
    }
}

.MainNavIcon-Anordnung .path3 {
    &:before {
        content: $MainNavIcon-Anordnung-path3;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-Anordnung .path4 {
    &:before {
        content: $MainNavIcon-Anordnung-path4;
        margin-left: -1em;
        color: rgb(204, 204, 204);
    }
}

.MainNavIcon-Anordnung .path5 {
    &:before {
        content: $MainNavIcon-Anordnung-path5;
        margin-left: -1em;
        color: rgb(77, 77, 77);
    }
}

.MainNavIcon-Anordnung .path6 {
    &:before {
        content: $MainNavIcon-Anordnung-path6;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-Anordnung .path7 {
    &:before {
        content: $MainNavIcon-Anordnung-path7;
        margin-left: -1em;
        color: rgb(204, 204, 204);
    }
}

.MainNavIcon-Anordnung .path8 {
    &:before {
        content: $MainNavIcon-Anordnung-path8;
        margin-left: -1em;
        color: rgb(77, 77, 77);
    }
}

.MainNavIcon-Anordnung .path9 {
    &:before {
        content: $MainNavIcon-Anordnung-path9;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-Anordnung .path10 {
    &:before {
        content: $MainNavIcon-Anordnung-path10;
        margin-left: -1em;
        color: rgb(204, 204, 204);
    }
}

.MainNavIcon-Anordnung .path11 {
    &:before {
        content: $MainNavIcon-Anordnung-path11;
        margin-left: -1em;
        color: rgb(77, 77, 77);
    }
}

.MainNavIcon-Anordnung .path12 {
    &:before {
        content: $MainNavIcon-Anordnung-path12;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-Anordnung .path13 {
    &:before {
        content: $MainNavIcon-Anordnung-path13;
        margin-left: -1em;
        color: rgb(204, 204, 204);
    }
}

.MainNavIcon-Anordnung .path14 {
    &:before {
        content: $MainNavIcon-Anordnung-path14;
        margin-left: -1em;
        color: rgb(77, 77, 77);
    }
}

.MainNavIcon-Anordnung .path15 {
    &:before {
        content: $MainNavIcon-Anordnung-path15;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-Anordnung .path16 {
    &:before {
        content: $MainNavIcon-Anordnung-path16;
        margin-left: -1em;
        color: rgb(204, 204, 204);
    }
}

.MainNavIcon-Anordnung .path17 {
    &:before {
        content: $MainNavIcon-Anordnung-path17;
        margin-left: -1em;
        color: rgb(77, 77, 77);
    }
}

.MainNavIcon-Anordnung .path18 {
    &:before {
        content: $MainNavIcon-Anordnung-path18;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-Anordnung .path19 {
    &:before {
        content: $MainNavIcon-Anordnung-path19;
        margin-left: -1em;
        color: rgb(204, 204, 204);
    }
}

.MainNavIcon-Anordnung .path20 {
    &:before {
        content: $MainNavIcon-Anordnung-path20;
        margin-left: -1em;
        color: rgb(77, 77, 77);
    }
}

.MainNavIcon-Anordnung .path21 {
    &:before {
        content: $MainNavIcon-Anordnung-path21;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-Anordnung .path22 {
    &:before {
        content: $MainNavIcon-Anordnung-path22;
        margin-left: -1em;
        color: rgb(204, 204, 204);
    }
}

.MainNavIcon-Anordnung .path23 {
    &:before {
        content: $MainNavIcon-Anordnung-path23;
        margin-left: -1em;
        color: rgb(77, 77, 77);
    }
}

.MainNavIcon-Anordnung .path24 {
    &:before {
        content: $MainNavIcon-Anordnung-path24;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-Anordnung .path25 {
    &:before {
        content: $MainNavIcon-Anordnung-path25;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-Ausgabe .path1 {
    &:before {
        content: $MainNavIcon-Ausgabe-path1;
        color: rgb(153, 153, 153);
    }
}

.MainNavIcon-Ausgabe .path2 {
    &:before {
        content: $MainNavIcon-Ausgabe-path2;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-Ausgabe .path3 {
    &:before {
        content: $MainNavIcon-Ausgabe-path3;
        margin-left: -1em;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-Ausgabe .path4 {
    &:before {
        content: $MainNavIcon-Ausgabe-path4;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-Ausgabe .path5 {
    &:before {
        content: $MainNavIcon-Ausgabe-path5;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path1 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path1;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-CAD-Plan .path2 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path2;
        margin-left: -1em;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-CAD-Plan .path3 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path3;
        margin-left: -1em;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-CAD-Plan .path4 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path4;
        margin-left: -1em;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-CAD-Plan .path5 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path5;
        margin-left: -1em;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-CAD-Plan .path6 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path6;
        margin-left: -1em;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-CAD-Plan .path7 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path7;
        margin-left: -1em;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-CAD-Plan .path8 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path8;
        margin-left: -1em;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-CAD-Plan .path9 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path9;
        margin-left: -1em;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-CAD-Plan .path10 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path10;
        margin-left: -1em;
        color: rgb(204, 204, 204);
    }
}

.MainNavIcon-CAD-Plan .path11 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path11;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path12 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path12;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path13 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path13;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path14 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path14;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path15 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path15;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path16 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path16;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path17 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path17;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path18 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path18;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path19 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path19;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path20 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path20;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path21 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path21;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path22 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path22;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path23 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path23;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path24 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path24;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path25 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path25;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path26 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path26;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path27 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path27;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path28 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path28;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path29 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path29;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path30 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path30;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path31 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path31;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path32 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path32;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path33 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path33;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path34 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path34;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path35 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path35;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path36 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path36;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path37 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path37;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path38 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path38;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path39 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path39;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path40 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path40;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path41 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path41;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path42 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path42;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path43 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path43;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path44 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path44;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path45 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path45;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path46 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path46;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path47 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path47;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path48 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path48;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path49 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path49;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path50 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path50;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path51 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path51;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path52 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path52;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path53 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path53;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path54 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path54;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path55 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path55;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path56 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path56;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path57 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path57;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path58 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path58;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path59 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path59;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path60 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path60;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path61 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path61;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path62 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path62;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path63 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path63;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-CAD-Plan .path64 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path64;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-CAD-Plan .path65 {
    &:before {
        content: $MainNavIcon-CAD-Plan-path65;
        margin-left: -1em;
        color: rgb(102, 102, 102);
    }
}

.MainNavIcon-Dach .path1 {
    &:before {
        content: $MainNavIcon-Dach-path1;
        color: rgb(230, 230, 230);
    }
}

.MainNavIcon-Dach .path2 {
    &:before {
        content: $MainNavIcon-Dach-path2;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-E-Designer .path1 {
    &:before {
        content: $MainNavIcon-E-Designer-path1;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-E-Designer .path2 {
    &:before {
        content: $MainNavIcon-E-Designer-path2;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-E-Designer .path3 {
    &:before {
        content: $MainNavIcon-E-Designer-path3;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-E-Designer .path4 {
    &:before {
        content: $MainNavIcon-E-Designer-path4;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-E-Designer .path5 {
    &:before {
        content: $MainNavIcon-E-Designer-path5;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-Elektrik .path1 {
    &:before {
        content: $MainNavIcon-Elektrik-path1;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-Elektrik .path2 {
    &:before {
        content: $MainNavIcon-Elektrik-path2;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-Home {
    &:before {
        content: $MainNavIcon-Home;
    }
}

.MainNavIcon-Konstruktion {
    &:before {
        content: $MainNavIcon-Konstruktion;
    }
}

.MainNavIcon-Projekt .path1 {
    &:before {
        content: $MainNavIcon-Projekt-path1;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-Projekt .path2 {
    &:before {
        content: $MainNavIcon-Projekt-path2;
        margin-left: -1em;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-Projekt .path3 {
    &:before {
        content: $MainNavIcon-Projekt-path3;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-PV-Module .path1 {
    &:before {
        content: $MainNavIcon-PV-Module-path1;
        color: rgb(204, 204, 204);
    }
}

.MainNavIcon-PV-Module .path2 {
    &:before {
        content: $MainNavIcon-PV-Module-path2;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-PV-Module .path3 {
    &:before {
        content: $MainNavIcon-PV-Module-path3;
        margin-left: -1em;
        color: rgb(255, 255, 255);
    }
}

.MainNavIcon-Statik {
    &:before {
        content: $MainNavIcon-Statik;
        color: #666;
    }
}

.MainNavIcon-Wirtschaftlichkeit .path1 {
    &:before {
        content: $MainNavIcon-Wirtschaftlichkeit-path1;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-Wirtschaftlichkeit .path2 {
    &:before {
        content: $MainNavIcon-Wirtschaftlichkeit-path2;
        margin-left: -1em;
        color: rgb(102, 102, 102);
    }
}

.MainNavIcon-Wirtschaftlichkeit .path3 {
    &:before {
        content: $MainNavIcon-Wirtschaftlichkeit-path3;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-Wirtschaftlichkeit .path4 {
    &:before {
        content: $MainNavIcon-Wirtschaftlichkeit-path4;
        margin-left: -1em;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-Wirtschaftlichkeit .path5 {
    &:before {
        content: $MainNavIcon-Wirtschaftlichkeit-path5;
        margin-left: -1em;
        color: rgb(128, 128, 128);
    }
}

.MainNavIcon-Wirtschaftlichkeit .path6 {
    &:before {
        content: $MainNavIcon-Wirtschaftlichkeit-path6;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-Wirtschaftlichkeit .path7 {
    &:before {
        content: $MainNavIcon-Wirtschaftlichkeit-path7;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-Wirtschaftlichkeit .path8 {
    &:before {
        content: $MainNavIcon-Wirtschaftlichkeit-path8;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-Wirtschaftlichkeit .path9 {
    &:before {
        content: $MainNavIcon-Wirtschaftlichkeit-path9;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-Wirtschaftlichkeit .path10 {
    &:before {
        content: $MainNavIcon-Wirtschaftlichkeit-path10;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}

.MainNavIcon-Wirtschaftlichkeit .path11 {
    &:before {
        content: $MainNavIcon-Wirtschaftlichkeit-path11;
        margin-left: -1em;
        color: rgb(0, 0, 0);
    }
}
