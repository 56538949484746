﻿/*@import "variables";*/

/*override bootstrap variables:*/
// write here
$font-size-xl: 1.75rem;

/*@import "../../node_modules/bootstrap/scss/variables";*/

$primary-sunify: #4B00A3;
$secondary-sunify: #2AFE9D;

$theme-colors: ( 
    "facebook": $facebook, 
    "twitter": $twitter, 
    "linkedin": $linkedin,
    //"primary-sunify": $primary-sunify,
    //"secondary-sunify": $secondary-sunify
);
