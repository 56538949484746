﻿/*@import "variables";*/

$icons20X20: url('../../content/icons/icons_layout_20x20.png');
$icons30X30: url('../../content/icons/icons_layout_30x30.png');

.mapDrawerIconSpacer {
    /*margin-bottom: 14px;*/
    height: 11px;
    margin: 0px;
    width: 30px;
    background-image: url('../../content/images/TB_Spacer.png');
    background-repeat: no-repeat;
    background-position-y: 5px;
}

.mapDrawerIcon {
    width: 40px;
    height: 40px;
    @extend .d-flex-center;
    /*padding: .25rem;*/
    /*background-color: transparent;*/
    /*border: 1px solid #f6f6f6;*/
    /*border-radius: 4px;
    height: 30px;
    margin: 4px 0;
    width: 30px;*/
}

.mapDrawerIconImg {
    background-color: transparent;
    /*border: medium none;*/
    height: 30px;
    width: 30px;
}

.mapDrawerIcon:focus {
    outline: 0;
    box-shadow: none;
}

.mapDrawerIcon:hover {
    /*border: 1px solid #dadada;*/
    background-color: #dadada;
}

.mapDrawerIcon-selected {
    /*border: 1px solid #b4b4b4;*/
    background-color: #dadada;
}

.mapDrawerIcon-disabled {
    border: 1px solid #f6f6f6 !important;
    cursor: default !important;
    -ms-opacity: 0.4;
    opacity: 0.4;
}

.mapDrawerSlideOut {
    background-color: #f6f6f6;
    box-shadow: 4px 5px 3px 0 rgba(0, 0, 0, 0.2);
    margin: 0px 0 0 39px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    -ms-transition: max-width 0.5s ease-out 0s;
    -o-transition: max-width 0.5s ease-out 0s;
    -webkit-transition: max-width 0.5s ease-out 0s;
    transition: max-width 0.5s ease-out 0s;
    z-index: 101;
}

.mapDrawerSlideOut:hover {
    z-index: 103;
}

.mapDrawerSubPane {
    margin-left: 32px;
    margin-top: -2px;
    position: absolute;
}

.mapDrawerSubIcon {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #f6f6f6;
    border-color: #b4b4b4 #b4b4b4 #b4b4b4 #f6f6f6;
    border-image: none;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 30px;
    padding: 1px;
    position: absolute;
    width: 30px;
    z-index: 102;
}

.mapDrawerSubIcon:hover {
    background-color: #dadada;
}

.mapDrawerSubIcon:first-child {
    border-left-color: #b4b4b4;
}

.mapDrawerSubIcon-selected {
    background-color: #b4b4b4;
}

.mapDrawerWrapper {
    background-color: #f6f6f6;
}

.mapDrawerMenuTd {
    vertical-align: top;
}

.mapDrawerMenu {
    padding: 0 5px 0 5px;
    /*width: 34px;*/
}

#clientMapView *, #clientMapView ::after, #clientMapView ::before {
    /*box-sizing: content-box;*/
}

.maDrawerSlideOutWrapper {
    margin-top: 10px;
}

.mapDrawerViewWrapper {
    overflow: hidden;
}

.ribbonImgDeactivated, .ribbonImgDeactivated::before {
    /*-ms-opacity: 0.5;
        opacity: 0.5;*/
    /*-moz-filter: brightness(200%);
        -webkit-filter: brightness(200%);
        filter: brightness(200%)*/
    opacity: 0.2 !important;
    cursor: inherit;
}

.ribbonImgDeactivated:hover, .ribbonImgDeactivated::before:hover {
    /*-ms-opacity: 0.5;
        opacity: 0.5;*/
    /*-moz-filter: brightness(200%);
        -webkit-filter: brightness(200%);
        filter: brightness(200%)*/
    opacity: 0.2 !important;
    filter: Alpha(Opacity=20) !important;
    cursor: inherit;
}

.mapDrawerDetailsView {
    position: absolute;
    top: 5px;
    right: 5px;
    width: unset; /*320px*/
    min-height: 30px;
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
    z-index: 100;
}

.mdDetailsPane {
    width: 100%;
    /*height: 30px;
    color: white;
    font-weight: bold;*/
    cursor: pointer;
}

.mdDetailsPane > div {
    float: left;
    height: 20px;
    width: 20px;
    margin: 4px 0 0 4px;
}

.mdDetailsPane > span {
    line-height: 28px;
    padding-left: 4px;
}

.mdDetailsContent {
    font-size: 0.9em;
    width: auto;
    max-width: 500px;
    background: rgba(246,246,246, 0.9);
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 50px;
    -ms-transition: background-color 0.3s linear;
    -o-transition: background-color 0.3s linear;
    -webkit-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
}

.mdDetailsContent .spLabel {
    display: inline-block;
    word-break: break-all;
}

.mdDetailsContent .ui-widget-content {
    background-color: transparent;
}

.mdDetailsContentFocus {
    background: rgba(246,246,246, 1);
}

.mdDetailsContent > table {
    margin: 5px;
}

.mdDetailsWrapper {
    overflow: hidden;
}

.webgrid-row-style {
    max-height: 20px;
    height: 20px;
    background-color: #fff;
}

.webgrid-row-selected {
    /*background: #888888;*/
    background-color: #dadada;
    font-weight: bold;
}



.md-html-overlay-view-wrapper {
    position: absolute;
    z-index: 99;
}

.md-html-overlay-view {
    width: calc(100% - 315px);
    height: calc(100% - 10px);
    background: rgb(240,240,240);
    -webkit-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.25);
    margin: 5px;
    text-align: center;
    overflow: hidden;
}

.md-html-overlay-view > img {
    margin: 5px;
}

.md-section-headline {
    color: #1582c5;
    line-height: 40px;
    padding-left: 30px;
    font-size: 1.2em;
}

.md-section-name, .md-section-value {
    line-height: 30px;
    padding-left: 2px;
}

.md-bg-edit {
    background: $icons20X20 no-repeat -80px -40px;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.md-section-hori-line {
    width: 100%;
    border-bottom: 1px #007fc4 solid;
    height: 5px;
    margin-bottom: 5px;
}

.md-inter td {
    padding: 2px 2px 2px 0;
    margin: 0;
}

.md-inter-selected td {
    border-bottom: 1px solid #007fc4;
    border-top: 1px solid #007fc4;
}

.md-inter-selected td:first-child {
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 1px solid #007fc4;
}

.md-inter-selected td:last-child {
    -webkit-border-top-right-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    -moz-border-radius-topright: 3px;
    -moz-border-radius-bottomright: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-right: 1px solid #007fc4;
}

.dialogScrollLeft {
    background-position: center;
    width: 15px;
    height: 47px;
    cursor: pointer;
    background: url("./content/icons/slide_left.png");
}

.dialogScrollRight {
    background-position: center;
    right: 0;
    width: 15px;
    height: 47px;
    cursor: pointer;
    background: url("./content/icons/slide_right.png");
}

.md-roof-details-ribbon {
    display: inline-block;
}

.inline-md-ribbonItem {
    margin: 10px 0 0 10px;
    float: left;
}

.inline-md-ribbonItem > .RibbonItem {
    float: none;
    padding: 0;
    margin: 0;
}

.inline-md-ribbonItem .RibbonItem .RibbonItemText .ribbon_label {
    color: #384547;
}

#tt_rf-len .inline-md-ribbonItem {
    float: right;
}

#md-interferenceDetails table {
    border-collapse: separate;
    border-spacing: 0;
}

.md-inter-list, .md-inter-plist {
    max-height: 200px;
    width: 274px;
    overflow-y: auto;
    overflow-x: hidden;
}

.md-inter-title > input {
    width: 90px;
}

.md-inter-height > input {
    width: 50px;
}

.md-roof-slope > input {
    width: 30px;
}

.md-roof-height > input {
    width: 30px;
}

.md-inter-unit > span, .md-slope-unit > span {
    margin: 0 3px;
}

.md-td-chckbx > input {
    width: 20px;
}

.mapDrawerDetailsView .tdInput > input[type='checkbox'] {
    width: 20px;
}

.md-inter-delete > div, .md-inter-p-delete > div {
    background: $icons20X20 no-repeat -40px -40px;
    -ms-background-clip: padding-box;
    background-clip: padding-box;
    width: 20px;
    height: 20px;
    float: right;
    cursor: pointer;
    padding: 1px;
    border: 1px solid #384547;
    -ms-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}

.md-inter-delete-locked, .md-inter-unit-locked {
    -ms-opacity: 0.6;
    opacity: 0.6;
}

.md-inter-delete-locked > div {
    cursor: default;
}

.md-inter-lock {
    background: $icons20X20 no-repeat -120px -40px;
    cursor: pointer;
    margin: 1px;
    width: 20px;
    height: 20px;
    -ms-opacity: 0.6;
    opacity: 0.6;
}

.md-inter-lock:hover {
    -ms-opacity: 0.4;
    opacity: 0.4;
}

.md-inter-unlock {
    background: $icons20X20 no-repeat -100px -40px;
    cursor: pointer;
    margin: 1px;
    width: 20px;
    height: 20px;
}

.md-inter-unlock:hover {
    -ms-opacity: 0.6;
    opacity: 0.6;
}

.md-chained {
    background: $icons20X20 no-repeat -140px -40px;
    cursor: pointer;
    margin: 1px;
    width: 20px;
    height: 20px;
}

.md-chained:hover {
    -ms-opacity: 0.8;
    opacity: 0.8;
}

.md-unchained {
    background: $icons20X20 no-repeat -140px -40px;
    cursor: pointer;
    margin: 1px;
    width: 20px;
    height: 20px;
    -ms-opacity: 0.4;
    opacity: 0.4;
}

.md-unchained:hover {
    -ms-opacity: 0.2;
    opacity: 0.2;
}

.md-inter-p-delete > div {
    margin-left: 2px;
}

.md-inter-delete > div:hover, .md-inter-p-delete > div:hover {
    -ms-opacity: 0.6;
    opacity: 0.6;
}

.md-inter-delete-locked > div:hover {
    -ms-opacity: 1;
    opacity: 1;
}

.md-inter-p > td > input {
    width: 50px;
}

.md-inter-p .tdLabel {
    width: 40px;
}

.md-tile {
    width: 255px;
    height: 255px;
    background-color: white;
    border-bottom: dashed 1px rgba(0,0,0,0.2);
    border-right: dashed 1px rgba(0,0,0,0.2);
    border-top: none;
    border-left: none;
}

.md-tile-url {
    width: 256px;
    height: 256px;
    background-size: auto;
    background-repeat: no-repeat;
}

.md-tile-img {
    margin: 0;
    padding: 0;
    border: 0;
    border-image: none;
    width: 256px;
    height: 256px;
    max-width: none;
    -ms-user-select: none;
    user-select: none;
}

.md-lower {
    height: 30px;
    overflow: hidden;
    line-height: 29px;
}

.md-lower .spLabel {
    min-width: 50px;
}

.md-lower input {
    width: 200px;
}

.md-lower tr > td:not(:first-child) > .spLabel {
    padding-left: 20px;
    padding-right: 5px;
}

.md-canvas-url {
    width: 256px;
    height: 256px;
}

.md-reload {
    background: $icons20X20 no-repeat -40px -80px;
    cursor: pointer;
    margin: 1px;
    width: 20px;
    height: 20px;
}

.md-reload:hover {
    -ms-opacity: 0.8;
    opacity: 0.8;
}

.md-reload-inactive {
    background: $icons20X20 no-repeat -40px -80px;
    margin: 1px;
    width: 20px;
    height: 20px;
    -ms-opacity: 0.4;
    opacity: 0.4;
}

#tt_freeAreaDetails .md-inter-list .md-inter-title > input {
    width: auto;
}

.md-layer-select {
    background: none;
    position: absolute;
    margin-top: 60px;
    margin-left: 10px;
    overflow: visible;
    font-size: 1.1em;
    color: rgb(86, 86, 86);
    display: flex;
    z-index: 100;
}

.md-layer-item {
    /*height: 30px;
    line-height: 30px;
    margin-right: 10px;
    padding: 0 14px 0;
    text-align: center;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    border-radius: 2px;*/
    background: rgb(246,246,246);
    -ms-opacity: 0.8;
    opacity: 0.8;
    cursor: pointer;
    display: flex;
}

.md-layer-item:hover {
    -ms-opacity: 0.7;
    opacity: 0.7;
}

.md-layer-item-selected {
    -ms-opacity: 1;
    opacity: 1;
    color: black;
    font-weight: bold;
}

.md-layer-dot {
    width: 10px;
    height: 10px;
    border: 2px solid black;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    border-radius: 10px;
    background: black;
    margin: 8px 0 0 8px;
}

.md-wg-row {
    line-height: 29px;
}

/* custom adrian canvas controls center */
.canvas-controls-center {
    position: absolute;
    /*top: 0px;*/
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    pointer-events: none;
    /*font-size: 16px;*/
}

.clickable-element {
    pointer-events: auto;
}

.badge-pill-important {
    padding-right: .6em !important;
    padding-left: .6em !important;
    border-radius: 10rem !important;
}